import { Dispatch } from "redux";
import { BaseApiClient } from "./BaseApiClient";
import { getHeader, postContactUs, postFunraiser } from "../actions";
import { HeaderResponse } from "../models";
import { contactUsResponse, fundraiserResponse } from "../models/ApiResponses/FooterResponse";

class FooterClient extends BaseApiClient {
    public async postSubscriber(data: any, dispatch: Dispatch) {
        try {
            const subscriberData = await this.postFormData<any>(
              "public/sendMail",
              this.generateFormData(data)
            );

            console.log("after update subscriberData : ", subscriberData);
            
            return subscriberData;
          } catch (error: any) {
            console.log("error : ", error.metadata);
            error = JSON.parse(error.metadata.apiErrorMessage);
            console.log("error : ", error);
            return error;
          }
    }
     //lines added for contact us.
        public async postContactUs(data: any,dispatch: Dispatch) {
            try {
              const contactUsData = await this.postFormData<any>(
                "public/sendContactUsMail",
                this.generateFormData(data)
              );
              dispatch(postContactUs(contactUsData));
              return contactUsData;
            } catch (error) {
              throw error; 
            }
          }
     //lines added for fUNDRAISER.
     public async postFundraiser(data: any, dispatch: Dispatch) {
      try {
        const fundraiserData = await this.postFormData<any>(
          "public/sendFundraiseMail", 
          this.generateFormData(data)
        );
        dispatch(postFunraiser(fundraiserData));
        return fundraiserData; 
      } catch (error) {
        throw error; 
      }
    }
}

export const footerClient = new FooterClient();
