import React, { createContext, Component, ReactNode } from 'react';

// Define context type
interface ScrollContextType {
  scrollToTop: () => void;
}

// Define props for ScrollProvider component
interface ScrollProviderProps {
  children: ReactNode;
}

// Define state for ScrollProvider
interface ScrollProviderState {
  scrollRef: React.RefObject<HTMLDivElement>;
}

// Create context with a default value for scrollToTop (empty function)
const ScrollContext = createContext<ScrollContextType>({
  scrollToTop: () => {}, // Provide a noop function as the default value
});

// ScrollProvider class component
export class ScrollProvider extends Component<ScrollProviderProps, ScrollProviderState> {
  constructor(props: ScrollProviderProps) {
    super(props);
    this.state = {
      scrollRef: React.createRef<HTMLDivElement>(),
    };
  }

  // Function to scroll to the top
  scrollToTop = () => {
    if (this.state.scrollRef.current) {
      this.state.scrollRef.current.scrollTop = 0;
    }
  };

  render() {
    return (
      <ScrollContext.Provider value={{ scrollToTop: this.scrollToTop }}>
        <div ref={this.state.scrollRef} style={{ overflowY: 'auto', height: '100vh' }}>
          {this.props.children} {/* this should work correctly now */}
        </div>
      </ScrollContext.Provider>
    );
  }
}

// Context consumer hook for accessing the context in class components
export const ScrollConsumer = ScrollContext.Consumer;
