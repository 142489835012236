import React, { useState, useEffect } from 'react';
import { Check, Cross, Loader, Login } from './StyledComponent';

interface ApiResponse {
  success: boolean;
  message: string;
  error: string;
}

const EmailVerification: React.FC = () => {
  const [status, setStatus] = useState<'waiting' | 'success' | 'failure'>('failure');
  const [message, setMessage] = useState<string>('Please wait...');
  const [token, setToken] = useState<string | null>(null);

  // Extract token from the URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, []);

  useEffect(() => {
    if (token) {
      // Simulate an API call
      setStatus('waiting');
      setMessage('Verifying your email...');

      // Replace this with your actual API endpoint
      const verifyEmail = async () => {
        console.log(token);
        const backendDomain = process.env.BASE_URL || "https://favorsbythebay.com/apis";
        try {
          const response = await fetch(`${backendDomain}/user/verifymail?token=${token}`);
          const data: ApiResponse = await response.json();

          if (data.success) {
            setStatus('success');
            setMessage(data.message || 'Email verified successfully!');
          } else {
            setStatus('failure');
            setMessage(data.error || 'Verification failed.');
          }
        } catch (error) {
          setStatus('failure');
          setMessage('Something went wrong. Please try again later.');
        }
      };

      verifyEmail();
    }
  }, [token]);

  return (
    <div className="verification-container" style={{ textAlign: 'center', padding: '50px' }}>
     {status === 'waiting' &&
       <>
        <h1>Email Verification in progress</h1>      
       <Loader/>
       <p>Please wait...</p>
       </>}
      {status === 'success' &&
      <>
      <h1>Verification Successful!</h1>
      <Check/>
      <p style={{ color: 'green' }}>{message}</p>
      <Login>Go to Login</Login>
      </>
      }
      {status === 'failure' &&
      <>
      <h1>Verification Failed.</h1>
      <Cross/>
      <p style={{ color: 'red' }}>{message}.  Please try again.</p>
      <Login>Retry</Login>
      </>
      }
    </div>
  );
};

export default EmailVerification;
