import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  CartSizeQuantity,
  CustomizedProduct,
  IAppState,
  ICartState,
  DesignerViewStates
} from "../../models";
import { landingClient } from "../../ApiClients/LandingClient";
import { cartClient } from "../../ApiClients/CartClient";
import styled from "styled-components";
import { TextBeta, TextTheta, TinyText } from "@happeouikit/typography";
import {
  marginL,
  marginM,
  marginS,
  marginXL,
  paddingM,
  paddingS,
  primaryColor,
} from "../../constants";
import { DummyCartItem, ICartItem } from "../../models/interfaces/CartItems";

import CartInfo from "./CartInfo";
import CartCTA from "./CartCTA";
import { IOneProductV2 } from "../../models/ApiResponses/IGetProductResponseV2";
import { toast } from "@happeouikit/toast";
import { ConfirmModal } from "@happeouikit/modals";

interface CartPreviewProps {
  onNext(): void;
  onBack(): void;
  cart: ICartState;
  getCart(): void;
  selectedProduct: IOneProductV2;
  changeViewState(newState: DesignerViewStates): void;
  setEditProduct(prodt : any): void;
}

interface CartPreviewState {
  // items: DummyCartItem;
  cartData: any;
  openAccordionIndices: Record<number, boolean>;
  selectedItem: any;
  isOpenConfirmModal: boolean;
}

export class CartPreviewComponent extends React.Component<
  CartPreviewProps,
  CartPreviewState
> {
  constructor(props: CartPreviewProps) {
    super(props);
    this.state = {
      cartData: [],
      // items: DummyCartItem.data.customizedProducts,
      openAccordionIndices: {},
      selectedItem: false,
      isOpenConfirmModal: false,
    };
    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  componentDidMount(): void {
    cartClient.getCart1().then((cartData) => {
      console.log(cartData, "cartDatacartDatacartData");
      this.setState({
        cartData: cartData,
      });
    });
    // this.props.getCart();
  }

  toggleAccordion(index: number) {
    const { openAccordionIndices } = this.state;
    if (openAccordionIndices[index]) {
      openAccordionIndices[index] = false;
    } else {
      openAccordionIndices[index] = true;
    }

    this.setState({ openAccordionIndices: { ...openAccordionIndices } });
  }

  private getPrice(item: CartSizeQuantity[]): string {
    console.log("🚀 ~ file: CartPreview.tsx:69 ~ getPrice ~ item:", item);

    return (Math.random() * 10).toFixed();
  }

  addQuotation = () => {
    const { cartData } = this.state;
    let cartIds =
      cartData.length > 0
        ? cartData.map((i: any) => {
            return i._id;
          })
        : [];
    const data = {
      cartIds: cartIds,
      totalPrice: 400,
      currency: "$",
    };
    cartClient.addQuotation(data).then((re) => {
      if (!re.error) {
        toast.error({ message: "Quotaion has been sent!" });
      } else {
        toast.error({ message: "Something went wrong" });
      }
    });
  };

  addOrder = () => {
    const { cartData } = this.state;
    let userDetails: any = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);
    console.log("cartData : ", cartData);
    let totalAmount = 0;

    let cartItemTotalPriceArr =
      cartData.length > 0
        ? cartData.map((i: any) => {
            return i.totalPrice;
          })
        : [];

    totalAmount = cartItemTotalPriceArr.reduce(
      (acc: any, item: any) => (acc += item),
      totalAmount
    );

    const data = {
      userId: userDetails.userId,
      productList: cartData,
      shippingAddressId: null,
      totalAmount: totalAmount + "",
      currency: "$",
    };
    console.log("orderData : ", data);

    cartClient.addOrder(data).then((re) => {
      if (!re.error) {
        toast.success({ message: "Order has been created!" });
        /* call cart list api */
        cartClient.getCart1().then((cartData) => {
          console.log(cartData, "cartDatacartDatacartData");
          this.setState({
            cartData: cartData,
          });
        });
      } else {
        toast.error({ message: "Something went wrong" });
      }
    });
  };

  addShipping = () => {
    this.props.onNext();
  };

  deleteCartItemConfirm = (cartItem: any) => {
    this.setState({
      selectedItem: cartItem,
      isOpenConfirmModal: true,
    });
  };

  closeConfirmModal = () => {
    this.setState({
      selectedItem: false,
      isOpenConfirmModal: false,
    });
  };

  deleteCartItem = (cartItem: any) => {
    if (!cartItem) {
      toast.error({ message: "Invalid cart item." });
      return false;
    }

    const cartData = {
      _id: cartItem._id,
    };

    cartClient.deleteCartItem(cartData).then((re) => {
      if (!re.error) {
        toast.success({ message: "Cart Item has been deleted!" });
        this.closeConfirmModal();
        /* call cart list api */
        cartClient.getCart1().then((cartData) => {
          console.log(cartData, "cartDatacartDatacartData");
          this.setState({
            cartData: cartData,
          });
        });
      } else {
        toast.error({ message: "Something went wrong" });
      }
    });
  };

  cloneCartItem = (cartItem: any) => {
    if (!cartItem) {
      toast.error({ message: "Invalid cart item." });
      return false;
    }

    const cartData = {
      _id: cartItem._id,
    };

    cartClient.copyCart(cartData).then((re) => {
      if (!re.error) {
        toast.success({ message: "Cart Item has been copied!" });
        this.closeConfirmModal();
        /* call cart list api */
        cartClient.getCart1().then((cartData) => {
          console.log(cartData, "cartDatacartDatacartData");
          this.setState({
            cartData: cartData,
          });
        });
      } else {
        toast.error({ message: "Something went wrong" });
      }
    });
  }

  editCartItem = (cartItem: any) => {
    if (!cartItem) {
      toast.error({ message: "Invalid cart item." });
      return false;
    }

    console.log("selectedProduct : ", this.props.selectedProduct);
    console.log("editProduct : ", cartItem);

    localStorage.setItem('editProduct', JSON.stringify(cartItem));

    window.location.href = `/catalog/${cartItem.productId.categoryId}/subcategories/${cartItem.productId.subCategoryId}/products/${cartItem.productId._id}/design`;

    //this.props.changeViewState(DesignerViewStates.ColorAndQuantity);
    //this.props.setEditProduct(cartItem);
  }

  formatPrice = (price: any) => {
    return parseFloat(price).toFixed(2);
  }

  render(): React.ReactNode {
    const { openAccordionIndices, cartData, selectedItem, isOpenConfirmModal } =
      this.state;
    const { selectedProduct } = this.props;

    return (
      <>
        <MainContainer>
          <Wrapper>
            <SettingHeader>
              <TextBeta className="bold">My Cart</TextBeta>
              <TotalPrice>
                {/* <TextTheta>Estimate Total:</TextTheta> */}
              </TotalPrice>
            </SettingHeader>
          </Wrapper>
        </MainContainer>
        <Wrapper>
          <SecondaryContainer>
            {cartData.length > 0 ? (
              cartData.map((item: any, index: number) => {
                const isOpen = !!openAccordionIndices[index];
                return (
                  <CartItemContianer key={index} style={{ margin: "10px 0" }}>
                    <CartItemHeader
                      isOpen={isOpen}
                      onClick={() => this.toggleAccordion(index)}
                    >
                      <CartItemHeaderTitle>
                        {item.productId.name}
                      </CartItemHeaderTitle>
                      <CartItemHeaderPrice>
                        <ModifierContainer>
                          <span>{item.totalQuantity}</span>
                          <span> | </span>
                          <span>
                            Order Estimate: {item.currency} {this.formatPrice(item.totalPrice)}
                          </span>
                          <span> &nbsp;&nbsp;&nbsp; </span>
                        </ModifierContainer>
                        <span>
                          <ButtonAlpha
                            style={{ height: "fit-content", margin: "3.3%" }}
                            onClick={() => this.editCartItem(item)}
                          >
                            Copy
                          </ButtonAlpha>
                        </span>
                        {/*<span style={{marginLeft:"5px"}}>
                          <ButtonAlpha
                            style={{ height: "fit-content", margin: "3.3%" }}
                            onClick={() => {
                              this.cloneCartItem(item);
                              this.toggleAccordion(index);
                            }}
                          >
                            Copy
                          </ButtonAlpha>
                        </span> */}
                        <span style={{marginLeft:"5px"}}>
                          <ButtonAlpha
                            style={{ height: "fit-content", margin: "3.3%" }}
                            onClick={() => this.deleteCartItemConfirm(item)}
                          >
                            Delete
                          </ButtonAlpha>
                        </span>
                      </CartItemHeaderPrice>
                    </CartItemHeader>
                    {isOpen && <CartInfo cartDataItem={item} />}
                  </CartItemContianer>
                );
              })
            ) : (
              <h2>No Records</h2>
            )}
            <ConfirmModal
              isOpen={isOpenConfirmModal}
              close={this.closeConfirmModal}
              header={"Delete cart item"}
              bodyText={
                "Do you really want to delete? This action can't be reverted."
              }
              okCb={() => this.deleteCartItem(selectedItem)}
              okText={"Accept"}
              state={"warn"}
            />
            <CartCTA
              cartItemCount={cartData.length}
              addOrder={this.addOrder}
              addShipping={this.addShipping}
              hasShippingInfo={false}
            />
          </SecondaryContainer>
        </Wrapper>
      </>
    );
  }
}

export const MainContainer = styled.div`
  /* min-height: 500px; */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  display: flex;
  flex-direction: column;
`;

const SettingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${marginXL};
  p {
    margin-top: ${marginS};
  }
`;

const SecondaryContainer = styled.div`
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
`;

const Wrapper = styled.div``;

const TotalPrice = styled.div`
  p {
    color: ${primaryColor};
    font-size: 16px;
    font-weight: bold;
  }
`;

const CartItemContianer = styled.div``;

const CartItemHeader = styled.div<{ isOpen: boolean }>`
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (!props.isOpen ? "#ECECEC" : "#8585855e")};
  display: flex;
  padding: 8px 12px;
  border-radius: 5px;
  min-height: 60px;
  cursor: pointer;
`;

const CartItemHeaderTitle = styled.div`
  width: 40%;
`;

const CartItemHeaderPrice = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
`;

const ModifierContainer = styled.div`
  margin-top: ${marginM};
`;

const ModifierBreakDownTable = styled.table`
  width: 100%;
`;

const ButtonAlpha = styled.button`
  all: unset;
  text-align: left;
  letter-spacing: 0.51px;
  color: #ffffff;
  background: #142f8f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  cursor: pointer;
`;

const mapStateToProps = (state: IAppState) => {
  return {
    cart: state.cart,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getCart: () => cartClient.getCart(dispatch),
  };
};

export const CartPreview = connect(
  mapStateToProps,
  mapDispatchToProps
)(CartPreviewComponent);
