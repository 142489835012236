import React from "react";
import {
  IFontStyle,
  PreviewEmbroidarySettingNames,
  PreviewSettingNames,
  UpdatePreviewParams,
} from "../../models";
import {
  Embroidary,
  Embroidary_Postions,
  designWindowHeightValue,
  Embroidary_Text_Style
} from "../../constants";
import { Validation, isNumberLetters, isGreekLetters, generateGreekLetterSVG } from "../../Utils";

const ALTERATION_VALUE_X = 40;
const ALTERATION_VALUE_Y = 50;
interface EmbroidaryPreviewProps {
  EmDesignAtrributes: any;
  selectedSide: any;
  fontOptions: IFontStyle[];
  parsePreviewParams(keys: UpdatePreviewParams[]): any;
  parseddAppliquePreviewParams: any;
  appliqueRef: any;
  appliText: string;
  svgRef: any;
  appliqueBound: any;
  selectedProduct: any;
}

interface EmbroidaryPreviewState {
  appliqueBound: {};
  svgBound: {};
}

class RenderEmbroidaryPreview extends React.Component<
  EmbroidaryPreviewProps,
  EmbroidaryPreviewState
> {
  getStaticXValue = (imageSize: number) => {
    let s = 1;
    if (imageSize <= 1) {
      s = -imageSize * 2;
    } else if (imageSize > 1 && imageSize <= 1.5) {
      s = imageSize * 2;
    } else if (imageSize > 1.5 && imageSize < 3) {
      s = imageSize * 2;
    } else if (imageSize >= 3 && imageSize < 6) {
      s = imageSize * 6;
    } else {
      s = imageSize * 8;
    }
    return s;
  };

  getStaticXValueImage = (imageSize: number) => {
    let s = 1;
    if (imageSize <= 1) {
      s = imageSize * 10 + ALTERATION_VALUE_X;
    } else if (imageSize > 1 && imageSize <= 1.5) {
      s = imageSize * 5 + ALTERATION_VALUE_X;
    } else if (imageSize > 1.5 && imageSize < 3) {
      s = imageSize * 1 + ALTERATION_VALUE_X - 5;
    } else if (imageSize >= 3 && imageSize < 4) {
      s = imageSize * 1 + ALTERATION_VALUE_X - 12;
    } else if (imageSize >= 4 && imageSize < 6) {
      s = imageSize * 1 + ALTERATION_VALUE_X - 20;
    } else if (imageSize >= 6 && imageSize < 10) {
      s = imageSize * 1 + ALTERATION_VALUE_X - 30;
    } else if (imageSize >= 10 && imageSize < 12) {
      s = imageSize * 1 + ALTERATION_VALUE_X - 90;
    } else if (imageSize >= 12 && imageSize < 14) {
      s = imageSize * 1 + ALTERATION_VALUE_X - 100;
    } else {
      s = imageSize * 8 + ALTERATION_VALUE_X;
    }
    return s;
  };

  getStaticYValue = (imageSize: number) => {
    let s = 1;
    if (imageSize <= 1) {
      s = imageSize * 12;
    } else if (imageSize > 1 && imageSize <= 1.5) {
      s = imageSize * 14;
    } else if (imageSize > 1.5 && imageSize < 3) {
      s = imageSize * 15;
    } else if (imageSize >= 3 && imageSize < 6) {
      s = imageSize * 18;
    } else {
      s = imageSize * 19;
    }
    return s;
  };

  getStaticYValueImage = (imageSize: number) => {
    let s = 1;
    if (imageSize <= 1) {
      s = imageSize * 50;
    } else if (imageSize > 1 && imageSize <= 1.5) {
      s = imageSize * 30;
    } else if (imageSize > 1.5 && imageSize < 3) {
      s = imageSize * 12;
    } else if (imageSize >= 3 && imageSize < 4) {
      s = imageSize * 5;
    } else if (imageSize >= 4 && imageSize < 6) {
      s = imageSize * 1;
    } else if (imageSize >= 6 && imageSize < 10) {
      s = imageSize * 1 - 20;
    } else if (imageSize >= 10 && imageSize < 12) {
      s = imageSize * 1 - 40;
    } else if (imageSize >= 12 && imageSize < 14) {
      s = imageSize * 1 - 80;
    } else {
      s = imageSize * 8;
    }
    return s;
  };

  getStaticYBottomValue = (imageSize: number) => {
    let s = 1;
    if (imageSize <= 1) {
      s = imageSize * 6;
    } else if (imageSize > 1 && imageSize <= 1.5) {
      s = imageSize * 4;
    } else if (imageSize > 1.5 && imageSize < 3) {
      s = imageSize * 2;
    } else if (imageSize >= 3 && imageSize < 6) {
      s = imageSize * 2;
    } else {
      s = imageSize * 0.6;
    }
    return s;
  };

  getXLetter(key: string, currentEm: any) {
    const { appliqueRef, svgRef, appliText, parseddAppliquePreviewParams, selectedSide, EmDesignAtrributes, selectedProduct } = this.props;
    if(appliqueRef && svgRef){
      const { x: appliqueViewX, bottom: appliqueViewBottom } =
        appliqueRef.getBoundingClientRect();
      const { x: svgX } = svgRef.getBoundingClientRect();
      console.log("currentEm: ", currentEm);

      /* EmDesignAtrributes[0].map((i: any) => {
        const keys = Object.keys(i);
        const currentEm = this.props.parsePreviewParams(i[keys[0]]);
      }); */

      let imageSize =
        Number(currentEm.imageSize.split("in")[0]) * designWindowHeightValue;
        console.log("getXLetter key : ", key);
      switch (key) {
        case PreviewEmbroidarySettingNames.Design_1:
        case PreviewEmbroidarySettingNames.Design_2:
          const location = currentEm[PreviewSettingNames.embroidaryLocation];
          const letterPlacement = parseddAppliquePreviewParams[PreviewSettingNames.letterPlacement];
          console.log("======== location X : ", location);
          console.log("===letterSize X : ",parseddAppliquePreviewParams[PreviewSettingNames.letterSize]);
          console.log("===letterPlacement X : ",parseddAppliquePreviewParams[PreviewSettingNames.letterPlacement]);
          if (location === Embroidary.aboveGreekLetter) {
            if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
              if(letterPlacement.x_axis < 50){
                /* right */
                return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X - 20
              } else {
                /* left */
                return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 220
              }
              //return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 10;
            } else {
              if(letterPlacement.x_axis < 40){
                /* right */
                return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X - 40;
              }else {
                /* left */
                return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 220
              }
            }
            
          } else if (location === Embroidary.overGreekLetter) {
            if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
              if(letterPlacement.x_axis < 50){
                /* right */
                return parseInt(svgX) + ALTERATION_VALUE_X - 20;
              } else {
                /* left */
                return parseInt(svgX) + ALTERATION_VALUE_X + 220;
              }
            } else {
              if(letterPlacement.x_axis < 40){
                /* right */
                return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X - 50;
              }else {
                /* left */
                return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 220
              }
            }
          } else if (location === Embroidary.underGreekLetter) {
            if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
              if(letterPlacement.x_axis < 50){
                /* right */
                return parseInt(svgX) + ALTERATION_VALUE_X - 20
              } else {
                /* left */
                return parseInt(svgX) + ALTERATION_VALUE_X + 220;
              }
            } else {
              if(letterPlacement.x_axis < 40){
                /* right */
                return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X - 50;
              }else {
                /* left */
                return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 220
              }
            }
          } else if(location === Embroidary.aboveText){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
            if(selectedSide == "arm_left"){
              textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
            } else if(selectedSide == "arm_right"){
              textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
            }
            //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticXValue = this.getStaticXValueImage(imageSize);
            if(selectedSide == "arm_left"){ 
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("COLLAR")){ 
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue; break;
                  case 2: getStaticXValue = getStaticXValue; break;
                  case 3: getStaticXValue = getStaticXValue; break;
                }
              } else {
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 85; break;
                  case 2: getStaticXValue = getStaticXValue - 85; break;
                  case 3: getStaticXValue = getStaticXValue - 85; break;
                }
              }
            } else if(selectedSide == "arm_right"){ 
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("COLLAR")){ 
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue; break;
                  case 2: getStaticXValue = getStaticXValue; break;
                  case 3: getStaticXValue = getStaticXValue; break;
                }
              } else {
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 105; break;
                  case 2: getStaticXValue = getStaticXValue - 105; break;
                  case 3: getStaticXValue = getStaticXValue - 105; break;
                }
              }
            }
            
            return parseInt(svgX) + getStaticXValue;
          } else if(location === Embroidary.overText){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
            if(selectedSide == "arm_left"){
              textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
            } else if(selectedSide == "arm_right"){
              textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
            }
            //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticXValue = this.getStaticXValueImage(imageSize);
            if(selectedSide == "arm_left"){
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("COLLAR")){ 
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue; break;
                  case 2: getStaticXValue = getStaticXValue; break;
                  case 3: getStaticXValue = getStaticXValue; break;
                }
              } else {
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 85; break;
                  case 2: getStaticXValue = getStaticXValue - 85; break;
                  case 3: getStaticXValue = getStaticXValue - 85; break;
                }
              }
            } else if(selectedSide == "arm_right"){
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("COLLAR")){ 
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue; break;
                  case 2: getStaticXValue = getStaticXValue; break;
                  case 3: getStaticXValue = getStaticXValue; break;
                }
              } else {
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 105; break;
                  case 2: getStaticXValue = getStaticXValue - 105; break;
                  case 3: getStaticXValue = getStaticXValue - 105; break;
                }
              }
            }
            
            return parseInt(svgX) + getStaticXValue;
          } else if(location === Embroidary.underText){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
            if(selectedSide == "arm_left"){
              textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
            } else if(selectedSide == "arm_right"){
              textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
            }
            //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticXValue = this.getStaticXValueImage(imageSize);
            
            if(selectedSide == "arm_left"){
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("COLLAR")){ 
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue; break;
                  case 2: getStaticXValue = getStaticXValue; break;
                  case 3: getStaticXValue = getStaticXValue; break;
                }
              } else {
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 85; break;
                  case 2: getStaticXValue = getStaticXValue - 85; break;
                  case 3: getStaticXValue = getStaticXValue - 85; break;
                }
              }
            } else if(selectedSide == "arm_right"){ 
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("COLLAR")){ 
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue; break;
                  case 2: getStaticXValue = getStaticXValue; break;
                  case 3: getStaticXValue = getStaticXValue; break;
                }
              } else {
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 105; break;
                  case 2: getStaticXValue = getStaticXValue - 105; break;
                  case 3: getStaticXValue = getStaticXValue - 105; break;
                }
              }
            }
            
            return parseInt(svgX) + getStaticXValue;
          } else if(location === Embroidary.leftChest){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticXValue = this.getStaticXValueImage(imageSize);
            
            
            return parseInt(svgX) + getStaticXValue + 100;
          } else if(location === Embroidary.leftChestAlignToFirstGreekLetter){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticXValue = this.getStaticXValueImage(imageSize);
            
            
            return parseInt(svgX) + getStaticXValue + 100;
          } else if(location === Embroidary.rightChest){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticXValue = this.getStaticXValueImage(imageSize);
            
            
            return parseInt(svgX) + getStaticXValue - 70;
          } else if(location === Embroidary.rightChestAlignToFirstGreekLetter){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticXValue = this.getStaticXValueImage(imageSize);
            
            
            return parseInt(svgX) + getStaticXValue - 70;
          } else if(location === Embroidary.centreChest){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticXValue = this.getStaticXValueImage(imageSize);
            
            
            return parseInt(svgX) + getStaticXValue + 20;
          } else if(location === Embroidary.topOfBack){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticXValue = this.getStaticXValueImage(imageSize);
            
            
            return parseInt(svgX) + getStaticXValue + 20;
          } else if(location === Embroidary.centreOfBack){ console.log("++++");
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticXValue = this.getStaticXValueImage(imageSize);
            
            
            return parseInt(svgX) + getStaticXValue + 20;
          } else if(location === Embroidary.bottomOfBack){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticXValue = this.getStaticXValueImage(imageSize);
            
            
            return parseInt(svgX) + getStaticXValue + 20;
          } else if(location === Embroidary.underNumber){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticXValue = this.getStaticXValueImage(imageSize);
            
            
            return parseInt(svgX) + getStaticXValue + 10;
          }

          if(selectedSide == "back"){
            return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X - 90;
          }

          if (
            appliqueViewX === svgX &&
            currentEm.embroidaryLocation === "Right Arm"
          ) {
            if (imageSize <= 2) {
              return parseInt(appliqueViewX) - 80;
            }
            return parseInt(appliqueViewX) - 100;
          }
          if (
            appliqueViewX === svgX &&
            currentEm.embroidaryLocation === "Left Arm"
          ) {
            if (imageSize <= 2) {
              return parseInt(appliqueViewX) - 40;
            }
            return parseInt(appliqueViewX) - 60;
          }
        break;
        case PreviewEmbroidarySettingNames.Above :
        //if (Embroidary.aboveGreekLetter.indexOf(location) !== -1) {
          if(isGreekLetters(appliText)){
            return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 90;
          } else {
            return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 90;
          }
        break;
        case PreviewEmbroidarySettingNames.Over :
        //} else if (Embroidary.overGreekLetter.indexOf(location) !== -1) {
          if(isGreekLetters(appliText)){
            return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 80;
          } else {
            return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 80;
          }
        case PreviewEmbroidarySettingNames.Under :
        //} else if (Embroidary.underGreekLetter.indexOf(location) !== -1) {
          if(isGreekLetters(appliText)){
            return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 90;
          } else {
            return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 90;
          }
        //} else {
          //return Embroidary_Postions.CenterDesignX;
        case PreviewEmbroidarySettingNames.Top:
          if(isNumberLetters(appliText)){
            return parseInt(svgX) - ALTERATION_VALUE_X;
          } else {
            if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE") && selectedSide == "back"){
            
              return parseInt(svgX)+150;
            
            } else {
              return parseInt(svgX) + 80;
            }
          }
        case PreviewEmbroidarySettingNames.Bottom:
          if(isNumberLetters(appliText)){
            return parseInt(svgX) + 80;
          } else {
            return parseInt(svgX) + 80;
          }
        case PreviewEmbroidarySettingNames.UnderNumber:
          if(isNumberLetters(appliText)){
            return parseInt(svgX) + 80;
          } else {
            return parseInt(svgX);
          }
        case PreviewEmbroidarySettingNames.Left:
          const loc = currentEm[PreviewSettingNames.embroidaryLocation];
          const letterP = parseddAppliquePreviewParams[PreviewSettingNames.letterPlacement];
          if (selectedSide == "front") { 
            if (loc === Embroidary.aboveGreekLetter) {
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                if(letterP.x_axis < 50){
                  /* right */
                  return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X - 20
                } else {
                  /* left */
                  return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 220
                }
                //return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 10;
              } else {
                return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 90;
              }
              
            } else if (loc === Embroidary.overGreekLetter) {
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                if(letterP.x_axis < 50){
                  /* right */
                  return parseInt(svgX) + ALTERATION_VALUE_X - 20;
                } else {
                  /* left */
                  return parseInt(svgX) + ALTERATION_VALUE_X + 220;
                }
              } else {
                return Embroidary_Postions.CenterY;
              }
            } else if (loc === Embroidary.underGreekLetter) {
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                if(letterP.x_axis < 50){
                  /* right */
                  return parseInt(svgX) + ALTERATION_VALUE_X - 20
                } else {
                  /* left */
                  return parseInt(svgX) + ALTERATION_VALUE_X + 220;
                }
              } else {
                return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 90;
              }
            } else if(loc === Embroidary.aboveText){
              let textEmbdAttributes = {contentText : ''};
              let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
              if(selectedSide == "arm_left"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
              } else if(selectedSide == "arm_right"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
              }
              //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
              let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
              let getStaticXValue = this.getStaticXValueImage(imageSize);
              if(selectedSide == "arm_left"){
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 85; break;
                  case 2: getStaticXValue = getStaticXValue - 85; break;
                  case 3: getStaticXValue = getStaticXValue - 85; break;
                }
              } else if(selectedSide == "arm_right"){
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 105; break;
                  case 2: getStaticXValue = getStaticXValue - 105; break;
                  case 3: getStaticXValue = getStaticXValue - 105; break;
                }
              }
              
              return parseInt(svgX) + getStaticXValue;
            } else if(loc === Embroidary.overText){
              let textEmbdAttributes = {contentText : ''};
              let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
              if(selectedSide == "arm_left"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
              } else if(selectedSide == "arm_right"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
              }
              //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
              let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
              let getStaticXValue = this.getStaticXValueImage(imageSize);
              if(selectedSide == "arm_left"){
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 85; break;
                  case 2: getStaticXValue = getStaticXValue - 85; break;
                  case 3: getStaticXValue = getStaticXValue - 85; break;
                }
              } else if(selectedSide == "arm_right"){
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 105; break;
                  case 2: getStaticXValue = getStaticXValue - 105; break;
                  case 3: getStaticXValue = getStaticXValue - 105; break;
                }
              }
              
              return parseInt(svgX) + getStaticXValue;
            } else if(loc === Embroidary.underText){
              let textEmbdAttributes = {contentText : ''};
              let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
              if(selectedSide == "arm_left"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
              } else if(selectedSide == "arm_right"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
              }
              //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
              let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
              let getStaticXValue = this.getStaticXValueImage(imageSize);
              
              if(selectedSide == "arm_left"){
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 85; break;
                  case 2: getStaticXValue = getStaticXValue - 85; break;
                  case 3: getStaticXValue = getStaticXValue - 85; break;
                }
              } else if(selectedSide == "arm_right"){
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 100; break;
                  case 2: getStaticXValue = getStaticXValue - 100; break;
                  case 3: getStaticXValue = getStaticXValue - 100; break;
                }
              }
              
              return parseInt(svgX) + getStaticXValue;
            } else {
              return parseInt(svgX) + ALTERATION_VALUE_X + 110;
            }
          } else if(selectedSide == "arm_left"){
            if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("COLLAR")){
              return parseInt(svgX) + ALTERATION_VALUE_X
            } else {
            return parseInt(svgX) + ALTERATION_VALUE_X + 40;
            }
          } else if(selectedSide == "cuff_left"){ 
            if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("COLLAR")){
              return parseInt(svgX) + ALTERATION_VALUE_X + 20
            } else {
            return parseInt(svgX) + ALTERATION_VALUE_X - 80;
            }
          } else {
            return parseInt(svgX) + ALTERATION_VALUE_X + 120
          }
        break;
        case PreviewEmbroidarySettingNames.Right:
          const locR = currentEm[PreviewSettingNames.embroidaryLocation];
          const letterPR = parseddAppliquePreviewParams[PreviewSettingNames.letterPlacement];
          if (selectedSide == "front") { 
            if (locR === Embroidary.aboveGreekLetter) {
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                if(letterPR.x_axis < 50){
                  /* right */
                  return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X - 20
                } else {
                  /* left */
                  return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 220
                }
                //return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 10;
              } else {
                return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 90;
              }
              
            } else if (locR === Embroidary.overGreekLetter) {
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                if(letterPR.x_axis < 50){
                  /* right */
                  return parseInt(svgX) + ALTERATION_VALUE_X - 20;
                } else {
                  /* left */
                  return parseInt(svgX) + ALTERATION_VALUE_X + 220;
                }
              } else {
                return Embroidary_Postions.CenterY;
              }
            } else if (locR === Embroidary.underGreekLetter) {
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                if(letterPR.x_axis < 50){
                  /* right */
                  return parseInt(svgX) + ALTERATION_VALUE_X - 20
                } else {
                  /* left */
                  return parseInt(svgX) + ALTERATION_VALUE_X + 220;
                }
              } else {
                return parseInt(svgX) - (this.getStaticXValue(imageSize)) + ALTERATION_VALUE_X + 90;
              }
            } else if(locR === Embroidary.aboveText){
              let textEmbdAttributes = {contentText : ''};
              let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
              if(selectedSide == "arm_left"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
              } else if(selectedSide == "arm_right"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
              }
              //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
              let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
              let getStaticXValue = this.getStaticXValueImage(imageSize);
              if(selectedSide == "arm_left"){
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 85; break;
                  case 2: getStaticXValue = getStaticXValue - 85; break;
                  case 3: getStaticXValue = getStaticXValue - 85; break;
                }
              } else if(selectedSide == "arm_right"){
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 105; break;
                  case 2: getStaticXValue = getStaticXValue - 105; break;
                  case 3: getStaticXValue = getStaticXValue - 105; break;
                }
              }
              
              return parseInt(svgX) + getStaticXValue;
            } else if(locR === Embroidary.overText){
              let textEmbdAttributes = {contentText : ''};
              let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
              if(selectedSide == "arm_left"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
              } else if(selectedSide == "arm_right"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
              }
              //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
              let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
              let getStaticXValue = this.getStaticXValueImage(imageSize);
              if(selectedSide == "arm_left"){
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 85; break;
                  case 2: getStaticXValue = getStaticXValue - 85; break;
                  case 3: getStaticXValue = getStaticXValue - 85; break;
                }
              } else if(selectedSide == "arm_right"){
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 105; break;
                  case 2: getStaticXValue = getStaticXValue - 105; break;
                  case 3: getStaticXValue = getStaticXValue - 105; break;
                }
              }
              
              return parseInt(svgX) + getStaticXValue;
            } else if(locR === Embroidary.underText){
              let textEmbdAttributes = {contentText : ''};
              let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
              if(selectedSide == "arm_left"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
              } else if(selectedSide == "arm_right"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
              }
              //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
              let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
              let getStaticXValue = this.getStaticXValueImage(imageSize);
              
              if(selectedSide == "arm_left"){
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 85; break;
                  case 2: getStaticXValue = getStaticXValue - 85; break;
                  case 3: getStaticXValue = getStaticXValue - 85; break;
                }
              } else if(selectedSide == "arm_right"){
                switch(embdTextLen){
                  case 1: getStaticXValue = getStaticXValue - 100; break;
                  case 2: getStaticXValue = getStaticXValue - 100; break;
                  case 3: getStaticXValue = getStaticXValue - 100; break;
                }
              }
              
              return parseInt(svgX) + getStaticXValue;
            } else {
              return parseInt(svgX) + ALTERATION_VALUE_X - 50;
            }
          } else if(selectedSide == "arm_right"){ 
            if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("COLLAR")){
              return parseInt(svgX) + ALTERATION_VALUE_X + 20
            } else {
            return parseInt(svgX) + ALTERATION_VALUE_X - 85
            }
          } else if(selectedSide == "cuff_right"){
            if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("COLLAR")){
              return parseInt(svgX) + ALTERATION_VALUE_X + 20
            } else {
            return parseInt(svgX) + ALTERATION_VALUE_X - 90;
            }
          } else {
            return parseInt(svgX) + ALTERATION_VALUE_X - 50
          }
          //return parseInt(svgX) - this.getStaticXValue(imageSize) + ALTERATION_VALUE;
        //}
        break;
        case PreviewEmbroidarySettingNames.On_Collar:
          if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("COLLAR")){
            return parseInt(svgX) + ALTERATION_VALUE_X + 20
          } else {
          return parseInt(svgX) + ALTERATION_VALUE_X - 70;
          }
        case PreviewEmbroidarySettingNames.On_Collar_Flipped_Up:
          if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("COLLAR")){
            return parseInt(svgX) + ALTERATION_VALUE_X + 20
          } else {
          return parseInt(svgX) + ALTERATION_VALUE_X - 90;
          }
        default:
          return parseInt(Embroidary_Postions.CenterY);
      }
    } else {
      return parseInt(Embroidary_Postions.CenterY);
    }
  }

  getYLetter(key: string, currentEm: any) {
    const { appliqueRef, svgRef, parseddAppliquePreviewParams, appliText, selectedSide, EmDesignAtrributes, selectedProduct } = this.props;
    if(appliqueRef && svgRef){
      const { y: appliqueViewY, bottom: appliqueViewBottom } =
        appliqueRef.getBoundingClientRect();
      const { y: svgY, bottom: svgBottom } = svgRef.getBoundingClientRect();
      let appliTextCount = appliText.length;
      let imageSize =
        Number(currentEm.imageSize.split("in")[0]) * designWindowHeightValue;
      console.log("getYLetter key : ", key);  
      let textEmbdAttributes = {contentText : ''};
      let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

      console.log("===letterSize Y : ",parseddAppliquePreviewParams[PreviewSettingNames.letterSize]);
      console.log("===letterPlacement Y : ",parseddAppliquePreviewParams[PreviewSettingNames.letterPlacement]);
      const letterPlacement = parseddAppliquePreviewParams[PreviewSettingNames.letterPlacement];
      switch (key) {
        case PreviewEmbroidarySettingNames.Above:
          //return appliqueViewY - svgY - ALTERATION_VALUE;
          /*console.log(
            "parseddAppliquePreviewParams[PreviewSettingNames.letterSize] : ",
            parseddAppliquePreviewParams[PreviewSettingNames.letterSize]
          );*/
          if(isGreekLetters(appliText)){
            switch (parseddAppliquePreviewParams[PreviewSettingNames.letterSize]) {
              case "0.4in":
                return svgY + ALTERATION_VALUE_Y - 0;
                break;
              case "0.6in":
                return svgY + ALTERATION_VALUE_Y + 5;
                break;
              case "0.8in":
                return svgY + ALTERATION_VALUE_Y + 5;
                break;
              default:
                return svgY + ALTERATION_VALUE_Y;
                break;
            }
          } else {
            return svgY + ALTERATION_VALUE_Y;
          }
        case PreviewEmbroidarySettingNames.Design_1:
        case PreviewEmbroidarySettingNames.Design_2:
          const location = currentEm[PreviewSettingNames.embroidaryLocation];
          if (location === Embroidary.aboveGreekLetter) {
            //return appliqueViewY - svgY - this.getStaticYValue(imageSize);
            let getStaticYValue =
              this.getStaticYValue(imageSize) + ALTERATION_VALUE_Y;
            //return svgY - this.getStaticYValue(imageSize);
            
            switch (
              parseddAppliquePreviewParams[PreviewSettingNames.letterSize]
            ) {
              case "0.4in":
                return svgY + getStaticYValue - 32;
                break;
              case "0.6in":
                return svgY + getStaticYValue + 20;
                break;
              case "0.8in":
                if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                  if(letterPlacement.x_axis < 50){
                    /* right */
                    return svgY + getStaticYValue - 65;
                  } else {
                    /* left */
                    return svgY + getStaticYValue - 65;
                  }
                } else {
                  return svgY + getStaticYValue - 45;
                }
                
      
                break;
              default:
                return svgY + getStaticYValue;
                break;
            }
          } else if (location === Embroidary.overGreekLetter) {
            if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
              if(letterPlacement.x_axis < 50){
                /* right */
                return svgY + 150;
              } else {
                /* left */
                return svgY + 150;
              }
            } else {
              if(letterPlacement.x_axis < 40){
                /* right */
                return appliqueViewY + svgY  + 50;
              } else {
                /* left */
                return appliqueViewY + svgY  + 50;
              }
            }
          } else if (location === Embroidary.underGreekLetter) {
            /* return (
              appliqueViewBottom - svgY - this.getStaticYBottomValue(imageSize)
            ); */
            //return svgY - this.getStaticYBottomValue(imageSize);
            let getStaticYBottomValue =
              this.getStaticYBottomValue(imageSize) + ALTERATION_VALUE_Y + 10;
            switch (
              parseddAppliquePreviewParams[PreviewSettingNames.letterSize]
            ) {
              case "0.4in":
                return svgY + getStaticYBottomValue + 40;
                break;
              case "0.6in":
                return appliqueViewY + svgY + getStaticYBottomValue + 120;
                break;
              case "0.8in":
                if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                  if(letterPlacement.x_axis < 50){
                    /* right */
                    return appliqueViewY + svgY + getStaticYBottomValue + 60;
                  } else {
                    /* left */
                    return appliqueViewY + svgY + getStaticYBottomValue + 60;
                  }
                } else {
                  if(letterPlacement.x_axis < 40){
                    /* right */
                    return appliqueViewY + svgY + getStaticYBottomValue + 100;
                  } else {
                    /* left */
                    return appliqueViewY + svgY + getStaticYBottomValue + 100;
                  }
                }
                break;
              default:
                return appliqueViewY + svgY + getStaticYBottomValue;
                break;
            }
          } else if(location === Embroidary.aboveText){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
            if(selectedSide == "arm_left"){
              textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
            } else if(selectedSide == "arm_right"){
              textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
            }
            //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
            switch(embdTextLen){
              case 1: getStaticYValue = getStaticYValue - 15; break;
              case 2: getStaticYValue = getStaticYValue - 15; break;
              case 3: getStaticYValue = getStaticYValue - 15; break;
            }

            return svgY + getStaticYValue;
          } else if(location === Embroidary.overText){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
            if(selectedSide == "arm_left"){
              textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
            } else if(selectedSide == "arm_right"){
              textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
            }
            //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
            
            if(selectedSide == "arm_left"){
              switch(embdTextLen){
                case 1: getStaticYValue = getStaticYValue + 45; break;
                case 2: getStaticYValue = getStaticYValue + 55; break;
                case 3: getStaticYValue = getStaticYValue + 65; break;
              }
            } else if(selectedSide == "arm_right"){
              switch(embdTextLen){
                case 1: getStaticYValue = getStaticYValue + 35; break;
                case 2: getStaticYValue = getStaticYValue + 85; break;
                case 3: getStaticYValue = getStaticYValue + 115; break;
              }
            }

            return svgY + getStaticYValue;
          } else if(location === Embroidary.underText){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
            if(selectedSide == "arm_left"){
              textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
            } else if(selectedSide == "arm_right"){
              textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
            }
            
            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            console.log("embdTextLen := ", textEmbdAttributes.contentText);
            let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
            if(selectedSide == "arm_left"){
              
              switch(embdTextLen){
                case 1: getStaticYValue = getStaticYValue + 25; break;
                case 2: getStaticYValue = getStaticYValue + 35; break;
                case 3: getStaticYValue = getStaticYValue + 45; break;
              }
            } else if(selectedSide == "arm_right"){
              
              switch(embdTextLen){
                case 1: getStaticYValue = getStaticYValue + 60; break;
                case 2: getStaticYValue = getStaticYValue + 70; break;
                case 3: getStaticYValue = getStaticYValue + 95; break;
              }
            }

            return svgY + getStaticYValue;
          } else if(location === Embroidary.leftChest){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
            
            
            return parseInt(svgY) + getStaticYValue + 100;
          } else if(location === Embroidary.leftChestAlignToFirstGreekLetter){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
            
            
            return parseInt(svgY) + getStaticYValue + 10;
          } else if(location === Embroidary.rightChest){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
            
            
            return parseInt(svgY) + getStaticYValue + 100;
          } else if(location === Embroidary.rightChestAlignToFirstGreekLetter){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
            
            
            return parseInt(svgY) + getStaticYValue + 10;
          } else if(location === Embroidary.centreChest){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
            
            
            return parseInt(svgY) + getStaticYValue + 10;
          }  else if(location === Embroidary.topOfBack){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
            
            
            return parseInt(svgY) + getStaticYValue - 80;
          } else if(location === Embroidary.centreOfBack){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
            
            
            return parseInt(svgY) + getStaticYValue + 40;
          } else if(location === Embroidary.bottomOfBack){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
            
            
            return parseInt(svgY) + getStaticYValue + 240;
          } else if(location === Embroidary.underNumber){
            let textEmbdAttributes = {contentText : ''};
            let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];

            let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
            let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
            
            
            return parseInt(svgY) + getStaticYValue + 140;
          } else {
            return Embroidary_Postions.CenterDesignY;
          }
        case PreviewEmbroidarySettingNames.Under:
          //return appliqueViewBottom - svgY + ALTERATION_VALUE;
          /*return (
            svgBottom - appliqueViewBottom - appliqueViewY + ALTERATION_VALUE
          );*/
          let YAdjust = 0;
          if(isGreekLetters(appliText)){
            
            switch (parseddAppliquePreviewParams[PreviewSettingNames.letterSize]) {
              case "0.4in":
                if(appliTextCount == 1){
                  return svgY + (appliTextCount*105);
                } else if(appliTextCount == 2){
                  return appliqueViewY + svgY - (appliTextCount*80);
                } else if(appliTextCount == 3){
                  return appliqueViewY + svgY - (appliTextCount*35);
                } else if(appliTextCount == 4){
                  return appliqueViewY + svgY - (appliTextCount*20);
                } else if(appliTextCount == 5){
                  return appliqueViewY + svgY - (appliTextCount*5);
                } else if(appliTextCount == 6){
                  return appliqueViewY + svgY + (appliTextCount*1);
                }
                return appliqueViewY + svgY - (appliTextCount*35);
                break;
              case "0.6in":
                if(appliTextCount == 1){
                  return svgY + (appliTextCount*125);
                } else if(appliTextCount == 2){
                  return svgY - (appliTextCount*200);
                } else if(appliTextCount == 3){
                  return appliqueViewY + svgY + (appliTextCount*80);
                } else if(appliTextCount == 4){
                  return appliqueViewY + svgY - (appliTextCount*1);
                } else if(appliTextCount == 5){
                  return appliqueViewY + svgY + (appliTextCount*10);
                } else if(appliTextCount == 6){
                  return appliqueViewY + svgY + (appliTextCount*20);
                }
                return appliqueViewY + svgY - (appliTextCount*15);
                break;
              case "0.8in":
                if(appliTextCount == 1){
                  return svgY + (appliTextCount*150);
                } else if(appliTextCount == 2){
                  return svgY + (appliTextCount*120);
                } else if(appliTextCount == 3){
                  return appliqueViewY + svgY + (appliTextCount*1);
                } else if(appliTextCount == 4){
                  return appliqueViewY + svgY + (appliTextCount*20);
                } else if(appliTextCount == 5){
                  return appliqueViewY + svgY + (appliTextCount*45);
                } else if(appliTextCount == 6){
                  return appliqueViewY + svgY + (appliTextCount*75);
                }
                return appliqueViewY + svgY - (appliTextCount*5);
                break;
              default:
                return svgY + ALTERATION_VALUE_Y;
                break;
            }
          } else {
            return svgY + ALTERATION_VALUE_Y;
          }
        case PreviewEmbroidarySettingNames.Over:
          if(isGreekLetters(appliText)){
            switch (parseddAppliquePreviewParams[PreviewSettingNames.letterSize]) {
              case "0.4in":
                if(appliTextCount == 1){
                  return svgY + (appliTextCount*105);
                } else if(appliTextCount == 2){
                  return svgY - (appliTextCount*150);
                } else if(appliTextCount == 3){
                  return appliqueViewY + svgY - (appliTextCount*35);
                } else if(appliTextCount == 4){
                  return appliqueViewY + svgY - (appliTextCount*40);
                } else if(appliTextCount == 5){
                  return appliqueViewY + svgY - (appliTextCount*5);
                } else if(appliTextCount == 6){
                  return appliqueViewY + svgY + (appliTextCount*1);
                }
                return appliqueViewY + svgY - (appliTextCount*35);
                break;
              case "0.6in":
                if(appliTextCount == 1){
                  return svgY + (appliTextCount*125);
                } else if(appliTextCount == 2){
                  return svgY - (appliTextCount*155);
                } else if(appliTextCount == 3){
                  return appliqueViewY + svgY - (appliTextCount*50);
                } else if(appliTextCount == 4){
                  return appliqueViewY + svgY - (appliTextCount*40);
                } else if(appliTextCount == 5){
                  return appliqueViewY + svgY + (appliTextCount*10);
                } else if(appliTextCount == 6){
                  return appliqueViewY + svgY + (appliTextCount*20);
                }
                return appliqueViewY + svgY - (appliTextCount*15);
                break;
              case "0.8in":
                if(appliTextCount == 1){
                  return svgY + (appliTextCount*150);
                } else if(appliTextCount == 2){
                  return svgY + (appliTextCount*70);
                } else if(appliTextCount == 3){
                  return appliqueViewY + svgY + (appliTextCount*1);
                } else if(appliTextCount == 4){
                  return appliqueViewY + svgY + (appliTextCount*20);
                } else if(appliTextCount == 5){
                  return appliqueViewY + svgY + (appliTextCount*45);
                } else if(appliTextCount == 6){
                  return appliqueViewY + svgY + (appliTextCount*75);
                }
                return appliqueViewY + svgY - (appliTextCount*5);
                break;
              default:
                return Embroidary_Postions.CenterY;
                break;
            }
          } else {
            return Embroidary_Postions.CenterY;
          }
        case PreviewEmbroidarySettingNames.Top:
          textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Top);
          
          console.log("textEmbdAttributes := ", textEmbdAttributes.contentText);
          //console.log((textEmbdAttributes.contentText != "")?svgY + ALTERATION_VALUE_Y - 30:0);
          if(isNumberLetters(appliText)){
            return (textEmbdAttributes.contentText != "")?svgY + ALTERATION_VALUE_Y - 40:0;
          } else {
            if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE") && selectedSide == "back"){
            
              return parseInt(svgY) - 100;
            
            } else { 
              return parseInt(svgY) + 30;
            } 
          }
        case PreviewEmbroidarySettingNames.Bottom:
          textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Bottom);
          if(isNumberLetters(appliText)){ 
            return (textEmbdAttributes.contentText != "")?svgY + ALTERATION_VALUE_Y + 290:svgY + ALTERATION_VALUE_Y + 290;
          } else {
            return parseInt(svgY) + 270;
          }
        case PreviewEmbroidarySettingNames.UnderNumber:
          textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].UnderNumber);
          if(isNumberLetters(appliText)){ 
            return (textEmbdAttributes.contentText != "")?svgY - ALTERATION_VALUE_Y + 250:svgY - ALTERATION_VALUE_Y + 250;
          } else {
            return parseInt(svgY);
          }
        case PreviewEmbroidarySettingNames.Right:
          let locR = currentEm[PreviewSettingNames.embroidaryLocation];
          if (selectedSide == "front") { 
            if(locR === Embroidary.aboveGreekLetter){
              //return appliqueViewY - svgY - this.getStaticYValue(imageSize);
              let getStaticYValue =
              this.getStaticYValue(imageSize) + ALTERATION_VALUE_Y;
              //return svgY - this.getStaticYValue(imageSize);
              
              switch (
                parseddAppliquePreviewParams[PreviewSettingNames.letterSize]
              ) {
                case "0.4in":
                  return svgY + getStaticYValue - 32;
                  break;
                case "0.6in":
                  return svgY + getStaticYValue + 20;
                  break;
                case "0.8in": 
                  if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                    if(letterPlacement.x_axis < 50){
                      /* right */
                      return svgY + getStaticYValue - 65;
                    } else {
                      /* left */
                      return svgY + getStaticYValue - 65;
                    }
                  } else {
                    return svgY + getStaticYValue - 45;
                  }
                  
        
                  break;
                default:
                  return svgY + getStaticYValue;
                  break;
              } 
            } else if (locR === Embroidary.overGreekLetter) {
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                if(letterPlacement.x_axis < 50){
                  /* right */
                  return svgY + 150;
                } else {
                  /* left */
                  return svgY + 150;
                }
              } else {
                return Embroidary_Postions.CenterY;
              }
            } else if (locR === Embroidary.underGreekLetter) {
              let getStaticYBottomValue =
              this.getStaticYBottomValue(imageSize) + ALTERATION_VALUE_Y + 10;
              switch (
                parseddAppliquePreviewParams[PreviewSettingNames.letterSize]
              ) {
                case "0.4in":
                  return svgY + getStaticYBottomValue + 40;
                  break;
                case "0.6in":
                  return appliqueViewY + svgY + getStaticYBottomValue + 120;
                  break;
                case "0.8in": 
                  if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                    if(letterPlacement.x_axis < 50){
                      /* right */
                      return appliqueViewY + svgY + getStaticYBottomValue + 60;
                    } else {
                      /* left */
                      return appliqueViewY + svgY + getStaticYBottomValue + 60;
                    }
                  } else {
                    return appliqueViewY + svgY + getStaticYBottomValue + 57;
                  }
                break;
                default:
                  return appliqueViewY + svgY + getStaticYBottomValue;
                  break;
              } 
            } else if(locR === Embroidary.aboveText){
              let textEmbdAttributes = {contentText : ''};
              let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
              if(selectedSide == "arm_left"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
              } else if(selectedSide == "arm_right"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
              }
              //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
              let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
              let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
              switch(embdTextLen){
                case 1: getStaticYValue = getStaticYValue - 15; break;
                case 2: getStaticYValue = getStaticYValue - 15; break;
                case 3: getStaticYValue = getStaticYValue - 15; break;
              }
    
              return svgY + getStaticYValue;
            } else if(locR === Embroidary.overText){
              let textEmbdAttributes = {contentText : ''};
              let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
              if(selectedSide == "arm_left"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
              } else if(selectedSide == "arm_right"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
              }
              //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
              let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
              let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
              
              if(selectedSide == "arm_left"){
                switch(embdTextLen){
                  case 1: getStaticYValue = getStaticYValue + 45; break;
                  case 2: getStaticYValue = getStaticYValue + 55; break;
                  case 3: getStaticYValue = getStaticYValue + 65; break;
                }
              } else if(selectedSide == "arm_right"){
                switch(embdTextLen){
                  case 1: getStaticYValue = getStaticYValue + 45; break;
                  case 2: getStaticYValue = getStaticYValue + 85; break;
                  case 3: getStaticYValue = getStaticYValue + 115; break;
                }
              }
    
              return svgY + getStaticYValue;
            } else if(locR === Embroidary.underText){
              let textEmbdAttributes = {contentText : ''};
              let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
              if(selectedSide == "arm_left"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
              } else if(selectedSide == "arm_right"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
              }
              
              let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
              console.log("embdTextLen := ", textEmbdAttributes.contentText);
              let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
              if(selectedSide == "arm_left"){
                
                switch(embdTextLen){
                  case 1: getStaticYValue = getStaticYValue + 25; break;
                  case 2: getStaticYValue = getStaticYValue + 35; break;
                  case 3: getStaticYValue = getStaticYValue + 45; break;
                }
              } else if(selectedSide == "arm_right"){
                
                switch(embdTextLen){
                  case 1: getStaticYValue = getStaticYValue + 30; break;
                  case 2: getStaticYValue = getStaticYValue + 70; break;
                  case 3: getStaticYValue = getStaticYValue + 95; break;
                }
              }
    
              return svgY + getStaticYValue;
            } else {
              return svgY + ALTERATION_VALUE_Y + 60;
            }
          } else if(selectedSide == "arm_right"){
            return svgY + ALTERATION_VALUE_Y + 60;
          } else if(selectedSide == "cuff_right"){
            return svgY + ALTERATION_VALUE_Y + 150;
          } else {
            return svgY + ALTERATION_VALUE_Y + 10;
          }
          //return Embroidary_Postions.RightY;
        break;
        case PreviewEmbroidarySettingNames.Left:
          let loc = currentEm[PreviewSettingNames.embroidaryLocation];
          if (selectedSide == "front") { 
            if(loc === Embroidary.aboveGreekLetter){
              //return appliqueViewY - svgY - this.getStaticYValue(imageSize);
              let getStaticYValue =
              this.getStaticYValue(imageSize) + ALTERATION_VALUE_Y;
              //return svgY - this.getStaticYValue(imageSize);
              
              switch (
                parseddAppliquePreviewParams[PreviewSettingNames.letterSize]
              ) {
                case "0.4in":
                  return svgY + getStaticYValue - 32;
                  break;
                case "0.6in":
                  return svgY + getStaticYValue + 20;
                  break;
                case "0.8in": 
                  if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                    if(letterPlacement.x_axis < 50){
                      /* right */
                      return svgY + getStaticYValue - 65;
                    } else {
                      /* left */
                      return svgY + getStaticYValue - 65;
                    }
                  } else {
                    return svgY + getStaticYValue - 45;
                  }
                  
        
                break;
                default:
                  return svgY + getStaticYValue;
                  break;
              } 
            } else if (loc === Embroidary.overGreekLetter) {
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                if(letterPlacement.x_axis < 50){
                  /* right */
                  return svgY + 150;
                } else {
                  /* left */
                  return svgY + 150;
                }
              } else {
                return Embroidary_Postions.CenterY;
              }
            } else if (loc === Embroidary.underGreekLetter) {
              let getStaticYBottomValue =
              this.getStaticYBottomValue(imageSize) + ALTERATION_VALUE_Y + 10;
              switch (
                parseddAppliquePreviewParams[PreviewSettingNames.letterSize]
              ) {
                case "0.4in":
                  return svgY + getStaticYBottomValue + 40;
                  break;
                case "0.6in":
                  return appliqueViewY + svgY + getStaticYBottomValue + 120;
                  break;
                case "0.8in": 
                  if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                    if(letterPlacement.x_axis < 50){
                      /* right */
                      return appliqueViewY + svgY + getStaticYBottomValue + 60;
                    } else {
                      /* left */
                      return appliqueViewY + svgY + getStaticYBottomValue + 60;
                    }
                  } else {
                    return appliqueViewY + svgY + getStaticYBottomValue + 57;
                  }
                break;
                default:
                  return appliqueViewY + svgY + getStaticYBottomValue;
                  break;
              } 
            } else if(loc === Embroidary.aboveText){
              let textEmbdAttributes = {contentText : ''};
              let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
              if(selectedSide == "arm_left"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
              } else if(selectedSide == "arm_right"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
              }
              //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
              let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
              let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
              switch(embdTextLen){
                case 1: getStaticYValue = getStaticYValue - 15; break;
                case 2: getStaticYValue = getStaticYValue - 15; break;
                case 3: getStaticYValue = getStaticYValue - 15; break;
              }
    
              return svgY + getStaticYValue;
            } else if(loc === Embroidary.overText){
              let textEmbdAttributes = {contentText : ''};
              let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
              if(selectedSide == "arm_left"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
              } else if(selectedSide == "arm_right"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
              }
              //console.log("leftEmbdAttributes := ", leftEmbdAttributes.contentText);
              let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
              let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
              
              if(selectedSide == "arm_left"){
                switch(embdTextLen){
                  case 1: getStaticYValue = getStaticYValue + 45; break;
                  case 2: getStaticYValue = getStaticYValue + 55; break;
                  case 3: getStaticYValue = getStaticYValue + 65; break;
                }
              } else if(selectedSide == "arm_right"){
                switch(embdTextLen){
                  case 1: getStaticYValue = getStaticYValue + 45; break;
                  case 2: getStaticYValue = getStaticYValue + 85; break;
                  case 3: getStaticYValue = getStaticYValue + 115; break;
                }
              }
    
              return svgY + getStaticYValue;
            } else if(loc === Embroidary.underText){
              let textEmbdAttributes = {contentText : ''};
              let selectedSideEmDesignAtrributes = EmDesignAtrributes[0];
              if(selectedSide == "arm_left"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Left);
              } else if(selectedSide == "arm_right"){
                textEmbdAttributes = this.props.parsePreviewParams(selectedSideEmDesignAtrributes[(selectedSideEmDesignAtrributes.length-1)].Right);
              }
              
              let embdTextLen = textEmbdAttributes.contentText.split("\n").length;
              console.log("embdTextLen := ", textEmbdAttributes.contentText);
              let getStaticYValue = this.getStaticYValueImage(imageSize) + ALTERATION_VALUE_Y;
              if(selectedSide == "arm_left"){
                
                switch(embdTextLen){
                  case 1: getStaticYValue = getStaticYValue + 25; break;
                  case 2: getStaticYValue = getStaticYValue + 35; break;
                  case 3: getStaticYValue = getStaticYValue + 45; break;
                }
              } else if(selectedSide == "arm_right"){
                
                switch(embdTextLen){
                  case 1: getStaticYValue = getStaticYValue + 30; break;
                  case 2: getStaticYValue = getStaticYValue + 70; break;
                  case 3: getStaticYValue = getStaticYValue + 95; break;
                }
              }
    
              return svgY + getStaticYValue;
            } else {
              return svgY + ALTERATION_VALUE_Y + 60;
            }
          } else if(selectedSide == "arm_left"){
            return svgY + ALTERATION_VALUE_Y + 50;
          } else if(selectedSide == "cuff_left"){
            return svgY + ALTERATION_VALUE_Y + 150;
          } else {
            return svgY + ALTERATION_VALUE_Y + 10;
          }
          //return Embroidary_Postions.LeftY;
        //case PreviewEmbroidarySettingNames.AboveText:

        //case PreviewEmbroidarySettingNames.UnderText:
        break;
        case PreviewEmbroidarySettingNames.Hood_Forward:
          return Embroidary_Postions.HoodY;
        case PreviewEmbroidarySettingNames.Hood_Backward:
          return Embroidary_Postions.HoodY; 
        case PreviewEmbroidarySettingNames.On_Collar:
          return svgY + ALTERATION_VALUE_Y - 80;
        case PreviewEmbroidarySettingNames.On_Collar_Flipped_Up:
          return svgY + ALTERATION_VALUE_Y - 60;
        default:
          return Embroidary_Postions.CenterY;
      }
    } else {
      return parseInt(Embroidary_Postions.CenterY);
    }
  }

  getPreviewSvgTextProperties(value: string) {
    const urlDefs: string[] = [];

    if (Validation.isValidUrl(value)) {
      const patternImgUrl = value.replace("http:","https:");
      urlDefs.push(patternImgUrl);
    }

    return { urlDefs };
  }

  displayMultilineTextSVG(part:{
    x: string | number | undefined,
    y: string | number | undefined,
    fontSize: number,
    lineHeight: number,
    style: any,
    flipStyle: any,
    fontFamily: string,
    text: string
  }){
    var drawx= Number(part.x)||0;
    var drawy= Number(part.y)||0;
    drawx = drawx < 0?-(drawx):drawx;
    drawy = drawy < 0?-(drawy):drawy;
    var fontSize=part.fontSize||14; 
    var lineHeight=part.lineHeight||1.25; 
    var style=part.style||""; 
    var flipStyle=part.flipStyle||"";
    var fontFamily=part.fontFamily||"Arial"; 
    //var text=part.text.split('\n').map(function(a,i){ return <tspan x={drawx} y={(drawy+fontSize*lineHeight+i*fontSize*lineHeight)}>{a}</tspan> }).join('\n');
    //console.log("part.text : ", part.text);
    /* check if text style */
    let text: any = "";
    if(part.text.indexOf(Embroidary_Text_Style.flipped) !== -1){
      /* flipped */
      text = part.text.replace(Embroidary_Text_Style.flipped, "");
      //console.log("displayFlippedTextWithFO text : ", text);
      text = this.displayFlippedTextWithHTML(text);
      /* return this.displayFlippedTextSVG({
        x: drawx,
        y: drawy,
        style: flipStyle,
        text: text,
        fontSize: fontSize,
        lineHeight: lineHeight
      }); */
      /* console.log("displayFlippedTextSVG : ", text.outerHTML); */
      return <foreignObject x={drawx} y={drawy} style={flipStyle}  dangerouslySetInnerHTML={{ __html:text}}></foreignObject>;
    } else {
      /* normal */
      text=part.text.split('\n').map(function(a,i){ return '<tspan x="'+drawx+'" y="'+(drawy+fontSize*lineHeight+i*fontSize*lineHeight)+'">'+a+'</tspan>' }).join('\n');
      /* wrap with text tag and return */
      return <text x={drawx} y={drawy} style={style}  dangerouslySetInnerHTML={{ __html:text}}></text>; /* fontFamily={fontFamily} fontSize={fontSize} */
    }
  }

  displayFlippedTextWithHTML(text: string){
    const outputElement = document.createElement('div'); //document.getElementById('output');
    outputElement.classList.add("flipped-text");
    outputElement.innerHTML = '';

    const textArr = text.split(" ").reverse();
    textArr.forEach((word: any, i:number) => {
      const flippedText = word.split("").reverse().join("");
      for (const char of flippedText) {
        const span = document.createElement('span');
        span.textContent = char;
        outputElement.appendChild(span);
      }

      // add space character
      const span = document.createElement('span');
      span.innerHTML = '&#160;';
      outputElement.appendChild(span);
    });

    /* convert html object to string and return */
    return outputElement.outerHTML;
  }

  displayFlippedTextSVG(part :{
    x: number,
    y: number,
    style: any,
    text: string,
    fontSize: number,
    lineHeight: number
  }) {
    var drawx=part.x||0;
    var drawy=part.y||0;
    var fontSize=part.fontSize||14; 
    var lineHeight=part.lineHeight||1.25; 
    var flipStyle=part.style||"";
    var text=part.text||"";

    /* const outputElement = document.createElement('div'); //document.getElementById('output');
    outputElement.classList.add("flipped-text");
    outputElement.innerHTML = ''; */
    
    const wordArr:Array<any> = [];
    const textArr = text.split(" ").reverse();
    let c = 0;
    textArr.forEach((word: any, i:number) => {
      const flippedText = word.split("").reverse().join("");
      const charArr:Array<any> = [];
      
      for (const char of flippedText) {
          /* const span = document.createElement('span');
          span.textContent = char;
          outputElement.appendChild(<tspan x={drawx} y={drawy} style={style}  dangerouslySetInnerHTML={{ __html:text}}></tspan>); */
          charArr.push(`<tspan x="${(drawy+fontSize*lineHeight+c*fontSize*lineHeight)}" y="${drawy}" style="display: inline-block; transform: rotate(180deg); transform-origin: top center;" >${char}</tspan>`);
          c++;
      }
      // add space character
      /* const span = document.createElement('span');
          span.innerHTML = '&#160;';
          outputElement.appendChild(span); */
      if(i < textArr.length-1){
          charArr.push(`<tspan x="${(drawy+fontSize*lineHeight+c*fontSize*lineHeight)}" y="${drawy}" style="display: inline-block; transform: rotate(180deg); transform-origin: top center;" >&#160;</tspan>`);
      }

      wordArr.push(charArr.join(""));
    });
    /* console.log("wordArr : ", wordArr); */
    return <foreignObject x={drawx} y={drawy}><svg x={drawx} y={drawy}><text x={drawx} y={drawy} style={flipStyle}  dangerouslySetInnerHTML={{ __html:wordArr.join("")}}></text></svg></foreignObject>;
    /* convert html object to string and return */
    //return outputElement.outerHTML;
  }

  render() {
    /* console.log(" EMBROIDARY RENDER :::::::::::::: "); */
    const { EmDesignAtrributes, selectedSide } = this.props;
    const renderEmbroidary =
      EmDesignAtrributes.length > 0 &&
      EmDesignAtrributes[0].map((i: any) => {
        const keys = Object.keys(i);
        const currentEm = this.props.parsePreviewParams(i[keys[0]]);
        /* console.log(currentEm, "currentEmcurrentEmcurrentEm"); */

        const design = currentEm[PreviewSettingNames.design];
        /*console.log(
          currentEm[PreviewSettingNames.letterSize],
          "currentEm[PreviewSettingNames.letterSize]"
        );*/

        const emFontFamilyValue = this.props.fontOptions.find(
          ({ _id }) =>
            _id === (currentEm as any)[PreviewSettingNames.fontFamily]
        );

        const emFontFamilyCssValue = emFontFamilyValue
          ? emFontFamilyValue.name
          : "inherit";

        return (
          <>
            <style type="text/css">
            {`
              .flipped-text {
                  /*font-size: 2rem;*/
                  /*line-height: 1;*/ /* Ensures no extra spacing between lines */
                  display: flex;
                  align-items: flex-start; /* Aligns items at the top */
                  
              }
              .flipped-text span {
                  display: inline-block;
                  transform: rotate(180deg); /* Rotates each character */
                  /*transform-origin: top center;*/ /* Rotates around the top */
                  /*margin-top:26%;*/
              }
            `}
            </style>
            <g
              style={{
                overflow: "hidden",
                textAnchor: "middle",
                //fontWeight: "bold",
                writingMode: "initial", 
                textOrientation: "initial"
              }}
            >
              {this.displayMultilineTextSVG({
                x: this.getXLetter(keys[0], currentEm),
                y: this.getYLetter(keys[0], currentEm),
                fontFamily: emFontFamilyCssValue,
                fontSize: currentEm[PreviewSettingNames.letterSize]*96 || 0.15*96,
                lineHeight: 1.25,
                style: {
                  fontSize:
                    currentEm[PreviewSettingNames.letterSize] || "0.15in",
                  fill: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  stroke: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  strokeWidth: 2,
                  fontFamily: emFontFamilyCssValue,
                },
                flipStyle: {
                  fontSize:
                    currentEm[PreviewSettingNames.letterSize] || "0.15in",
                  fill: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  //color: PreviewSettingNames.backGroundFillStrokeMarker,
                  stroke: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  color: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  strokeWidth: 2,
                  fontFamily: emFontFamilyCssValue,
                  lineHeight: 1,
                  display: "flex",
                  alignItems: "flex-start",
                  /* marginTop: "26%", */
                  width: "300px",
                  height: "50px"
                },
                text: currentEm?.contentText
              })}
              {/* <text
                x={`30%`}
                y={this.getYLetter(keys[0], currentEm)}
                style={{
                  fontSize:
                    currentEm[PreviewSettingNames.letterSize] || "0.15in",
                  fill: "transparent",
                  stroke: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  strokeWidth: 2,
                  fontFamily: emFontFamilyCssValue,
                }}
              >
                {currentEm?.contentText}
              </text> */}
              {this.displayMultilineTextSVG({
                x: this.getXLetter(keys[0], currentEm),
                y: this.getYLetter(keys[0], currentEm),
                fontFamily: emFontFamilyCssValue,
                fontSize: currentEm[PreviewSettingNames.letterSize]*96 || 0.15*96,
                lineHeight: 1.25,
                style: {
                  fontSize:
                    currentEm[PreviewSettingNames.letterSize] || "0.15in",
                  fill: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  stroke: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  strokeWidth: 1,
                  fontFamily: emFontFamilyCssValue,
                },
                flipStyle: {
                  fontSize:
                    currentEm[PreviewSettingNames.letterSize] || "0.15in",
                  fill: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  //color: PreviewSettingNames.backGroundFillStrokeMarker,
                  stroke: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  color: (currentEm as any)[
                    PreviewSettingNames.backGroundFillStrokeMarker
                  ],
                  strokeWidth: 2,
                  fontFamily: emFontFamilyCssValue,
                  lineHeight: 1,
                  display: "flex",
                  alignItems: "flex-start",
                  /* marginTop: "26%", */
                  width: "300px",
                  height: "50px"
                },
                text: currentEm?.contentText
              })}
              {/* <text
                x={`30%`}
                y={this.getYLetter(keys[0], currentEm)}
                style={{
                  fontSize:
                    currentEm[PreviewSettingNames.letterSize] || "0.15in",
                  fill: "#000",
                  stroke: "transparent",
                  strokeWidth: 1,
                  fontFamily: emFontFamilyCssValue,
                }}
              >
                {currentEm.contentText}
              </text> */}
              {design && (
                <image
                  href={design.replace("http:","https:")}
                  x={this.getXLetter(keys[0], currentEm)}
                  y={this.getYLetter(keys[0], currentEm)}
                  width={currentEm[PreviewSettingNames.imageSize] || "0.2in"}
                  height={currentEm[PreviewSettingNames.imageSize] || "0.2in"}
                />
              )}
            </g>
          </>
        );
      });
    return <>{renderEmbroidary}</>;
  }
}

export default RenderEmbroidaryPreview;
