import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { toast } from "@happeouikit/toast";
import Pattern from "../../images/bgPattern.png";
import { appliqueClient, categoryClient } from "../../ApiClients";
import Card from "../../components/Card";
import { 
  designWindowHeight, 
  paddingL, 
  marginM,
  marginS,
  marginXL,
  paddingM,
  paddingS,
  primaryColor,
  marginL } from "../../constants";
import {
  DesignerViewStates,
  IAppliqueData,
  IAppState,
  IFontStyle,
  PreviewSettingNames,
  CartData,
} from "../../models";
import Applique from "./Applique/Applique";
import AppliqueSelector from "./Applique/AppliqueSelector";
import { CartPreview } from "./CartPreview";
import { ColorSizeAndQuantitySetting } from "./ColorSizeAndQuantitySetting";
import EmboideryLocations from "./EmboideryLocations";
import { Shipping } from "./Shipping";

import { FloaterMenu } from "./FloaterMenu";
import Preview from "./Preview";
import SidePreview from "./SidePreview";
import {
  EmbroidaryID,
  IColorV2,
  IOneProductV2,
} from "../../models/ApiResponses/IGetProductResponseV2";
import {
  addColorToCart,
  removePriceToCart,
  updateProduct,
  addPriceToCart,
  updatePriceToCart,
  updateSizeQtyPriceToCart,
  updateQuantityToCart,
  updateProductIDToCart,
  updateEmbdryCollarFlippedUpPriceToCart,
  updateEmbdryHoodBackwardPriceToCart,
  addSpecialInstructionToCart
} from "../../actions";
import { cartClient } from "../../ApiClients/CartClient";
import { updateAppliqueToCart, updatePreviewImagesToCart } from "../../actions";
import { toJpeg } from "html-to-image";
import { Loader } from "@happeouikit/loaders";
import { Modal } from "@happeouikit/modals";
import { TextBeta, TextTheta } from "@happeouikit/typography";
import { SignUpForm } from "../../pages/SignUp/SignUp.form";
import { LoginForm } from "../../pages/Login/Login.form";
import { ILoginRequestParams, LoginResponse, ISignUpRequestParams, ISignupResponse } from "../../models";
import { authClient } from "../../ApiClients/AuthClient";
import { authCookieName, userDetails } from "../../constants";
import {setCookie, getCookie, deleteCookie} from "../../Utils/common";

interface ShirtDesignerProps {}

interface ShirtDesignerPropsFromRedux {
  getAllOptions(): void;
  getProduct(id: string): Promise<IOneProductV2>;
  fontOptions: IFontStyle[];
  selectedProduct(product: IOneProductV2): void;
  addColorToCart(data: any): void;
  removePriceToCart(data: any): void;
  addPriceToCart(data: any): void;
  updatePriceToCart(data: any): Promise<any>;
  updateEmbdryCollarFlippedUpPriceToCart(data: any): void;
  updateEmbdryHoodBackwardPriceToCart(data: any): void;
  updateSizeQtyPriceToCart(data: any): void;
  updateQuantityToCart(data: any): void;
  updateAppliqueToCart(data: any): void;
  updatePreviewImagesToCart(data: any): void;
  updateProductIDToCart(data: any): void;
  addSpecialInstructionToCart(data: any): void;
  cartData: any;
}

export enum SETTING_STATE {}
interface ShirtDesignerState {
  // designAtrributes: Record<string, UpdatePreviewParams[]>;
  designAtrributes: any;
  designerViewState: DesignerViewStates;
  selectedImage?: string;
  selectedProduct?: IOneProductV2;
  selectedSide: string;
  selectedColor?: IColorV2;
  selectedSizes?: Record<string, number>;
  selectedApplique?: IAppliqueData;
  selectedEmbroidaryOption?: Record<
    string,
    {
      emboidaryOption: EmbroidaryID;
      value: any;
    }
  >;
  designViews: any;
  navigationViews: any;
  screenLoader: boolean;
  previewImages: any;
  applicableEmbroidaries: any;
  confirmModalHTML: any;
  loginModalHTML: any;
  isConfirmModalOpen: Boolean;
  isLoginModalOpen: Boolean;
  signupModalHTML: any,
  isSignUpModalOpen: Boolean,
  isLoginModalVisible: Boolean,
  editCartProduct?: any;
  isFinalSubmission: boolean;
}

export class ShirtDesignerComponent extends React.Component<
  ShirtDesignerProps & ShirtDesignerPropsFromRedux,
  ShirtDesignerState
> {
  protected svgRef: React.RefObject<any>;
  constructor(props: ShirtDesignerProps & ShirtDesignerPropsFromRedux) {
    super(props);

    this.svgRef = React.createRef();

    this.state = {
      designAtrributes: {
        front: {
          [DesignerViewStates.Applique]: [
            { settingName: "contentText", settingVal: { value: "" } },
          ],
          [DesignerViewStates.EmboideryLocations]: [],
        },
        back: {
          [DesignerViewStates.Applique]: [
            { settingName: "contentText", settingVal: { value: "" } },
          ],
          [DesignerViewStates.EmboideryLocations]: [],
        },
        arm_left: {
          [DesignerViewStates.Applique]: [
            { settingName: "contentText", settingVal: { value: "" } },
          ],
          [DesignerViewStates.EmboideryLocations]: [],
        },
        arm_right: {
          [DesignerViewStates.Applique]: [
            { settingName: "contentText", settingVal: { value: "" } },
          ],
          [DesignerViewStates.EmboideryLocations]: [],
        },
        cuff_left: {
          [DesignerViewStates.Applique]: [
            { settingName: "contentText", settingVal: { value: "" } },
          ],
          [DesignerViewStates.EmboideryLocations]: [],
        },
        cuff_right: {
          [DesignerViewStates.Applique]: [
            { settingName: "contentText", settingVal: { value: "" } },
          ],
          [DesignerViewStates.EmboideryLocations]: [],
        },
        on_collar: {
          [DesignerViewStates.Applique]: [
            { settingName: "contentText", settingVal: { value: "" } },
          ],
          [DesignerViewStates.EmboideryLocations]: [],
        },
        collar_flipped_up: {
          [DesignerViewStates.Applique]: [
            { settingName: "contentText", settingVal: { value: "" } },
          ],
          [DesignerViewStates.EmboideryLocations]: [],
        },
        hood: {
          [DesignerViewStates.Applique]: [
            { settingName: "contentText", settingVal: { value: "" } },
          ],
          [DesignerViewStates.EmboideryLocations]: [],
        },
        hood_forward: {
          [DesignerViewStates.Applique]: [
            { settingName: "contentText", settingVal: { value: "" } },
          ],
          [DesignerViewStates.EmboideryLocations]: [],
        },
        hood_backward: {
          [DesignerViewStates.Applique]: [
            { settingName: "contentText", settingVal: { value: "" } },
          ],
          [DesignerViewStates.EmboideryLocations]: [],
        },
      },
      designerViewState: DesignerViewStates.ColorAndQuantity,
      selectedProduct: undefined,
      selectedSide: "front",
      selectedSizes: undefined, // selected sizes and quantity for cart,
      selectedApplique: undefined, // selected applique for cart
      selectedEmbroidaryOption: undefined, // selected emboidary for cart,
      designViews: {
        applique: [
          {
            view: "front",
            value: false,
          },
          {
            view: "back",
            value: false,
          },
        ],
        embroidary: [
          {
            view: "front",
            value: false,
          },
          {
            view: "back",
            value: false,
          },
          {
            view: "arm_left",
            value: false,
          },
          {
            view: "arm_right",
            value: false,
          },
          {
            view: "cuff_left",
            value: false,
          },
          {
            view: "cuff_right",
            value: false,
          },
          
          {
            view: "on_collar",
            value: false,
          },
          {
            view: "collar_flipped_up",
            value: false,
          },
          {
            view: "hood",
            value: false,
          },
          {
            view: "hood_forward",
            value: false,
          },
          {
            view: "hood_backward",
            value: false,
          }
        ],
      },
      navigationViews: [
        {
          name: "aplq_front",
          view: "front",
          visible: false,
          disabled: false
        },
        {
          name: "aplq_back",
          view: "back",
          visible: false,
          disabled: false
        },
        {
          name: "embd_front",
          view: "front",
          visible: false,
          disabled: false
        },
        {
          name: "embd_back",
          view: "back",
          visible: false,
          disabled: false
        },
        {
          name: "embd_arm_left",
          view: "arm_left",
          visible: false,
          disabled: false
        },
        {
          name: "embd_arm_right",
          view: "arm_right",
          visible: false,
          disabled: false
        },
        {
          name: "embd_cuff_left",
          view: "cuff_left",
          visible: false,
          disabled: false
        },
        {
          name: "embd_cuff_right",
          view: "cuff_right",
          visible: false,
          disabled: false
        },
        {
          name: "embd_on_collar",
          view: "on_collar",
          visible: false,
          disabled: false
        },
        {
          name: "embd_collar_flipped_up",
          view: "collar_flipped_up",
          visible: false,
          disabled: false
        },
        {
          name: "embd_hood",
          view: "hood",
          visible: false,
          disabled: false
        },
        /* {
          name: "hood_forward",
          visible: false,
        },
        {
          name: "hood_backward",
          visible: false,
        } */
      ],
      screenLoader: false,
      previewImages: {},
      applicableEmbroidaries: [],
      confirmModalHTML: "",
      isConfirmModalOpen: false,
      loginModalHTML: "",
      isLoginModalOpen: false,
      signupModalHTML: "",
      isSignUpModalOpen: false,
      isLoginModalVisible: true,
      editCartProduct: false,
      isFinalSubmission: false
    };

    this.updatePreview = this.updatePreview.bind(this);
    this.clearPreview = this.clearPreview.bind(this);
    this.changeViewState = this.changeViewState.bind(this);
    this.changeSide = this.changeSide.bind(this);
    this.selectColor = this.selectColor.bind(this);
    this.navViewUpdate = this.navViewUpdate.bind(this);
    this.setEditProduct = this.setEditProduct.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
  }

  navViewUpdate(isNext = 0, callBack = () => {}){
    /* 
      isNext :  0 -> nav back
                1 -> nav next
                2 -> only update preview
    */
    const { selectedSide, designerViewState, navigationViews, selectedColor, selectedProduct } = this.state;
    let currentNavViewName = "";
    let currentNavViewIndex = -1;
    let currentNavViewObj : {
      name: string,
      view: string,
      visible: boolean,
      disabled: boolean
    } = {
      name: "",
      view: "",
      visible: false,
      disabled: false
    };
    let nextNavViewObj:{
      name: string,
      view: string,
      visible: boolean,
      disabled: boolean
    } = {
      name: "",
      view: "",
      visible: false,
      disabled: false
    };

    if (designerViewState === DesignerViewStates.Applique) {
      currentNavViewName = "aplq_"+selectedSide;
      this.updateAppliqueDesignViews(
        {
          view: selectedSide,
          value: true,
        }
      );
    } else if(designerViewState === DesignerViewStates.EmboideryLocations) {
      currentNavViewName = "embd_"+selectedSide;
      this.updateEmbroidaryDesignViews(
        {
          view: selectedSide,
          value: true,
        }
      );
    }

    if(currentNavViewName != ""){
      currentNavViewIndex = navigationViews.findIndex((nav: any) => nav.name == currentNavViewName);
      if(currentNavViewIndex !== -1){
        currentNavViewObj = navigationViews[currentNavViewIndex];
        /* console.log("currentNavViewObj : ", currentNavViewObj); */
        navigationViews.splice(currentNavViewIndex, 1, {
          ...currentNavViewObj,
          visible: false
        });

        /* check if current view is applique front then move the view type */
        if(currentNavViewIndex == 0 && !isNext){
          this.changeViewState(
            DesignerViewStates.AppliqueTypeSelector
          );

          return false;
        }

        /* check if  */

        /* check if current view is applique back OR product type is stole and current view is applique front, then move the view type */
        if(
          (currentNavViewObj.name == "aplq_back" && isNext == 1)
          || 
          (selectedProduct && selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE") && currentNavViewObj.name == "aplq_front" && isNext == 1)
        ){
          this.savePreview(
            designerViewState + "_" + selectedSide,
            () => {
              let selectedAppliqueName = (this.state.selectedApplique as any)
                .name;
              /* console.log("selectedAppliqueName : " + selectedAppliqueName); */
              
              if (selectedAppliqueName == "Simple Text") {
                /* mark front as filled */
                this.updateEmbroidaryDesignViews({
                  view: "front",
                  value: true,
                });

                /* mark front embd view as disabled */
                let navIndex = navigationViews.findIndex((nav: any) => nav.name == "embd_front");
                let navViewObj = navigationViews[navIndex];
                navigationViews.splice(navIndex, 1, {
                  ...navViewObj,
                  disabled: true
                });
                this.setState({
                  navigationViews:navigationViews
                });

                this.setState({
                  selectedImage: (selectedColor?.images as any)[
                    "back"
                  ],
                  selectedSide: "back",
                });
              } else if (selectedAppliqueName == "Imprint") {
                /* mark front and back as filled */
                this.updateEmbroidaryDesignViews({
                  view: "front",
                  value: true,
                });
                this.updateEmbroidaryDesignViews({
                  view: "back",
                  value: true,
                });

                /* mark front and back embd views as disabled */
                let navIndex = navigationViews.findIndex((nav: any) => nav.name == "embd_front");
                let navViewObj = navigationViews[navIndex];
                navigationViews.splice(navIndex, 1, {
                  ...navViewObj,
                  disabled: true
                });
                navIndex = navigationViews.findIndex((nav: any) => nav.name == "embd_back");
                navViewObj = navigationViews[navIndex];
                navigationViews.splice(navIndex, 1, {
                  ...navViewObj,
                  disabled: true
                });
                this.setState({
                  navigationViews:navigationViews
                });

                this.setState({
                  selectedImage: (selectedColor?.images as any)[
                    "arm_left"
                  ],
                  selectedSide: "arm_left",
                });
              } else {
                /* console.log(
                  "designAtrributes after preview before embroidary: ",
                  this.state.designAtrributes
                ); */
                this.setState({
                  selectedImage: (selectedColor?.images as any)[
                    "front"
                  ],
                  selectedSide: "front",
                });
              }

              /* update view state to embroidary */
              this.changeViewState(DesignerViewStates.EmboideryLocations);
            }
          );

          return false;
        }

        /* check if current view is embroidary last view then move the view type */
        if(currentNavViewIndex == (navigationViews.length - 1) && isNext == 1){
          /* calculate total cart price */
          const { cartData } = this.props.cartData;
          /* console.log("cartData before savePreview", cartData); */
          let grandTotalPrice =
            cartData.sizeQtyPageTotalPrice +
            cartData.appliqueFrontPageTotalPrice +
            cartData.appliqueBackPageTotalPrice +
            cartData.embroidaryFrontPageTotalPrice +
            cartData.embroidaryBackPageTotalPrice +
            cartData.embroidaryLeftPageTotalPrice +
            cartData.embroidaryRightPageTotalPrice + 
            cartData.embroidaryLeftCuffPageTotalPrice + 
            cartData.embroidaryRightCuffPageTotalPrice + 
            cartData.embroidaryCollarPageTotalPrice + 
            cartData.embroidaryCollarFlippedUpPageTotalPrice + 
            cartData.embroidaryHoodPageTotalPrice + 
            cartData.embroidaryHoodForwardPageTotalPrice + 
            cartData.embroidaryHoodBackwardPageTotalPrice + 
            cartData.totalPrice;
          /* console.log("grandTotalPrice : ", grandTotalPrice); */
          this.props.updatePriceToCart({
            totalPrice: grandTotalPrice,
          });

          this.savePreview(
            this.state.designerViewState + "_" + this.state.selectedSide,
            () => {
              {
                (localStorage.getItem("userDetails") != null && getCookie(authCookieName) != null)?this.confirmationModalCall()
                :
                /* console.log("after updating grandTotalPrice : ", this.props.cartData); */
                this.handleLogiOrSignUpPopup()
                /* {isLoginModalVisible?this.loginModalOpen(
                  <>
                    <LoginForm handleLogin={this.handleLogin} />
                  </>
                ):this.loginModalOpen(
                  <>
                    <LoginForm handleLogin={this.handleLogin} />
                  </>
                )} */
              }
            }
          );

          return false;
        }

        do {
          if(isNext == 1){
            currentNavViewIndex++; // 1 step next
          } else if(isNext == 0) {
            if(selectedProduct && selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE") && currentNavViewObj.name == "embd_front"){
              currentNavViewIndex = currentNavViewIndex - 2; // 2 step back
            } else {
              currentNavViewIndex--; // 1 step back
            }
          }

          nextNavViewObj = navigationViews[currentNavViewIndex];
        } while(nextNavViewObj.name.includes("embd_") && nextNavViewObj.disabled);

        navigationViews.splice(currentNavViewIndex, 1, {
          ...nextNavViewObj,
          visible: true
        });
        this.setState({
          navigationViews:navigationViews
        });
        /* console.log("nextNavViewObj : ", nextNavViewObj); */
        
        if(nextNavViewObj.name.includes("embd_")){
          /* update view state to embroidary */
          this.changeViewState(DesignerViewStates.EmboideryLocations);
        } else {
          /* update view state to applique */
          this.changeViewState(DesignerViewStates.Applique);
        }

        /* save preview */
        this.savePreview(
          designerViewState + "_" + selectedSide,
          () => {
            /* console.log(
              "designAtrributes after preview applique: ",
              this.state.designAtrributes
            ); */
            this.setState({
              selectedImage: (selectedColor?.images as any)[
                nextNavViewObj.view
              ],
              selectedSide: nextNavViewObj.view,
            }, () => {
              if(typeof callBack === "function"){ 
                callBack();
              }
            });
          }
        );
      }
    }
  }

  updatePreview(params: any, key: string, side?:string) {
    const { selectedSide } = this.state;
    let sideSelected = side?side:selectedSide;
    this.setState((prevState) => {
      return {
        ...prevState,
        designAtrributes: {
          ...prevState.designAtrributes,
          [sideSelected]: {
            ...prevState.designAtrributes[sideSelected],
            [key]: [
              ...prevState.designAtrributes[sideSelected][key],
              params,
            ].map((obj: any) =>
              params.settingName === obj.settingName ? params : obj
            ),
          },
        },
      };
    });
  }

  private clearPreview(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.applicableDesignAttributes(resolve);
    });
  }

  private savePreview = (previewSide: any, callBack = () => {}) => {
    this.setState({ screenLoader: true });
    toJpeg(this.svgRef.current, { quality: 0.95 })
      .then((dataUrl) => {
        /*const link = document.createElement("a");
        link.download = "my-image-name.png";
        link.href = dataUrl;
        link.click();*/

        this.setState(
          {
            previewImages: {
              ...this.state.previewImages,
              [previewSide]: dataUrl,
            },
            screenLoader: false,
          },
          () => {
            this.props.updatePreviewImagesToCart(this.state.previewImages);
          }
        );
        setTimeout(() => callBack(), 10);
      })
      .catch((err) => {
        /* console.log(err); */
        this.setState(
          {
            /* previewImages: {
              ...this.state.previewImages,
              [previewSide]: dataUrl,
            }, */
            screenLoader: false,
          },
          () => {
            this.props.updatePreviewImagesToCart(this.state.previewImages);
          }
        );
        setTimeout(() => callBack(), 10);
      });
  };

  setEditProduct(prodt:any){
    this.setState({
      editCartProduct: prodt
    });
  }

  changeViewState(newState: DesignerViewStates) {
    this.setState({ designerViewState: newState });
  }

  componentDidMount(): void {
    this.props.getAllOptions();
    const allPathVars = window.location.pathname.split("/");
    this.props
      .getProduct(allPathVars[6])
      .then((selectedProduct) => {
        this.props.selectedProduct(selectedProduct);
        this.props.updateProductIDToCart(allPathVars[6]);

        let applicableEmbroidaries: {
          previewSideName : string,
          order: number
        }[] = [];
        if(selectedProduct.embroidaryId && selectedProduct.embroidaryId.length > 0){
          let selectedImages:any = this.state.selectedColor
                  ? this.state.selectedColor.images
                  : selectedProduct?.colors[0].images;
          //console.log("selectedImages : ", selectedImages);
          /* convert to previewSide names */
          let filteredEmbriodaries = selectedProduct.embroidaryId.filter((embroidary) => {
            let embdInfo = this.convertToPreviewSideValue(embroidary.location);
            //console.log(`selectedImages[${embdInfo.previewSideName}] : `, selectedImages[embdInfo.previewSideName]);
            return (selectedImages && selectedImages[embdInfo.previewSideName] && selectedImages[embdInfo.previewSideName] != "")?true:false;
          });
          /* console.log("filteredEmbriodaries : ", filteredEmbriodaries); */
          applicableEmbroidaries = filteredEmbriodaries.map((embroidary) => {
            return this.convertToPreviewSideValue(embroidary.location);
          });

          if(selectedProduct.typeId.type == "collar"){
            let len = applicableEmbroidaries.length;
            
            applicableEmbroidaries.push({
              previewSideName : "on_collar",
              order: (len+1)
            });
            applicableEmbroidaries.push({
              previewSideName : "collar_flipped_up",
              order: (len+2)
            });
          }

          if(selectedProduct.typeId.type == "hooded"){
            let len = applicableEmbroidaries.length;
            applicableEmbroidaries.push({
              previewSideName : "hood",
              order: (len+1)
            });
            /* applicableEmbroidaries.push({
              previewSideName : "hood_forward",
              order: (len+1)
            });
            applicableEmbroidaries.push({
              previewSideName : "hood_backward",
              order: (len+2)
            }); */
          }
          /* console.log("applicableEmbroidaries 1: ", applicableEmbroidaries); */
          /* remove duplicates */
          applicableEmbroidaries = applicableEmbroidaries.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.previewSideName === value.previewSideName 
            ))
          );

          /* sort */
          applicableEmbroidaries = applicableEmbroidaries.sort((a:any, b:any) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
        }
        /* console.log("applicableEmbroidaries 2: ", applicableEmbroidaries); */

        if(localStorage.getItem('editProduct') !== null){
          let editProduct:any = localStorage.getItem('editProduct') || "";
          editProduct = JSON.parse(editProduct);
          if(selectedProduct._id == editProduct.productId._id){
            this.setEditProduct(editProduct);
          }
        }

        const { navigationViews } = this.state;
        let applicableNavigationViews:Array<any> = [];
        navigationViews.map((navigationView : any) => {
          applicableEmbroidaries.map((embd : any) => {
            if(embd.previewSideName === navigationView.view){
              applicableNavigationViews.push(navigationView);
            }
          });
        });
        /* console.log("applicableNavigationViews: ", applicableNavigationViews); */

        this.setState({
          selectedProduct,
          selectedColor: selectedProduct.colors[0],
          applicableEmbroidaries: applicableEmbroidaries,
          navigationViews: applicableNavigationViews
        }, () => {
          /* update design attributes and views */
          this.applicableDesignAttributes();
          this.applicableDesignViews();
        });
      })
      .catch(console.error);
  }

  componentDidUpdate(
    prevProps: Readonly<ShirtDesignerProps & ShirtDesignerPropsFromRedux>,
    prevState: Readonly<ShirtDesignerState>,
    snapshot?: any
  ): void {
    if (prevState.selectedSide !== this.state.selectedSide) {
      if (this.state.designerViewState === DesignerViewStates.Applique) {
        /* this.updateAppliqueDesignViews({
          view: prevState.selectedSide,
          value: true,
        }); */
      }
    }
  }

  private changeSide(imageUrl: string, side: string) {
    if (
      this.state.designerViewState === DesignerViewStates.EmboideryLocations
    ) {
      let selectedAppliqueName = (this.state.selectedApplique as any).name;
      /* console.log("selectedAppliqueName : " + selectedAppliqueName); */
      /* console.log("changeSide : " + side); */
      if (selectedAppliqueName == "Simple Text" && side == "front") {
        // do nothing
        return true;
      } else {
        /* this.updateEmbroidaryDesignViews({
          view: this.state.selectedSide,
          value: true,
        }); */
      }
    }

    this.setState({
      selectedImage: imageUrl,
      selectedSide: side,
    }, () => {
      this.navViewUpdate(2);
    });
  }

  private selectColor(color: IColorV2) {
    const { selectedSide } = this.state;
    let imageUrl = color?.images?.front ;
    if (selectedSide && color.images) {
      imageUrl = (color?.images as any)[selectedSide];
    }
    this.setState({ selectedImage: imageUrl, selectedColor: color });
  }

  removeDuplicates = (array: any = []) => {
    array = Object.values(
      array.reduce(
        (acc: any, cur: any) => Object.assign(acc, { [cur.settingName]: cur }),
        {}
      )
    );

    return array;
  };

  addToCartAPI = () => {
    const { cartData } = this.props.cartData;
    /* console.log(cartData, "cartData"); */

    /* final submission true */
    this.setState({
      isFinalSubmission: true
    });

    delete cartData.sizeQtyPageTotalPrice;
    delete cartData.appliqueFrontPageTotalPrice;
    delete cartData.appliqueBackPageTotalPrice;
    delete cartData.embroidaryFrontPageTotalPrice;
    delete cartData.embroidaryBackPageTotalPrice;
    delete cartData.embroidaryLeftPageTotalPrice;
    delete cartData.embroidaryRightPageTotalPrice;
    delete cartData.embroidaryLeftCuffPageTotalPrice;
    delete cartData.embroidaryRightCuffPageTotalPrice;
    delete cartData.embroidaryCollarPageTotalPrice;
    delete cartData.embroidaryCollarFlippedUpPageTotalPrice;
    delete cartData.embroidaryHoodPageTotalPrice;
    delete cartData.embroidaryHoodForwardPageTotalPrice;
    delete cartData.embroidaryHoodBackwardPageTotalPrice;
    if(cartData.specialInstruction == ""){
      delete cartData.specialInstruction;
    }

    if(localStorage.getItem('editProduct') !== null){
      localStorage.removeItem('editProduct');
    }

    /* console.log(cartData, "cartData after all extra param delete"); */

    cartClient.addCart(cartData).then((re) => {
      /* console.log(re, "addCart api response"); */
      if (!re.error) {
        this.changeViewState(DesignerViewStates.Cart);
      } else {
        toast.error({ message: "Something went wrong" });
      }
    });
  };

  updateAppliqueDesignViews = (obj: any, from?: string) => {
    this.setState(
      (prevState) => {
        const indexU = prevState.designViews.applique.findIndex(
          (b: { view: string }) => {
            return b.view === obj.view;
          }
        );
        const updatedState = { ...obj };

        if (indexU !== -1) {
          return {
            ...prevState,
            designViews: {
              ...prevState.designViews,
              applique: [
                ...prevState.designViews.applique.slice(0, indexU),
                updatedState,
                ...prevState.designViews.applique.slice(indexU + 1),
              ],
            },
          };
        }
      },
      () => {
        /* const side = this.state.designViews.applique.find((i: any) => {
          return i.value === false || i.value === "false";
        });
        
        if (side) {
          
          this.savePreview(
            this.state.designerViewState + "_" + this.state.selectedSide,
            () => {
              
              this.setState({
                selectedImage: (this.state.selectedColor?.images as any)[
                  side.view
                ],
                selectedSide: side.view,
              });
            }
          );
        } else {
          if (from === "next") {
            
            this.savePreview(
              this.state.designerViewState + "_" + this.state.selectedSide,
              () => {
                let selectedAppliqueName = (this.state.selectedApplique as any)
                  .name;
                
                if (selectedAppliqueName == "Simple Text") {
                  // mark front and back as filled
                  this.updateEmbroidaryDesignViews({
                    view: "front",
                    value: true,
                  });
                  this.setState({
                    selectedImage: (this.state.selectedColor?.images as any)[
                      "back"
                    ],
                    selectedSide: "back",
                  });
                  // update view state to embroidary 
                  this.changeViewState(DesignerViewStates.EmboideryLocations);
                } else if (selectedAppliqueName == "Imprint") {
                  // mark front and back as filled 
                  this.updateEmbroidaryDesignViews({
                    view: "front",
                    value: true,
                  });
                  this.updateEmbroidaryDesignViews({
                    view: "back",
                    value: true,
                  });
                  this.setState({
                    selectedImage: (this.state.selectedColor?.images as any)[
                      "arm_right"
                    ],
                    selectedSide: "arm_right",
                  });
                  // update view state to embroidary 
                  this.changeViewState(DesignerViewStates.EmboideryLocations);
                } else {
                  
                  this.setState({
                    selectedImage: (this.state.selectedColor?.images as any)[
                      "front"
                    ],
                    selectedSide: "front",
                  });
                  this.changeViewState(DesignerViewStates.EmboideryLocations);
                }
              }
            );
          }
        } */
      }
    );
  };

  updateEmbroidaryDesignViews = (obj: any, from?: string, callBack = () => {}) => {
    this.setState(
      (prevState) => {
        const indexU = prevState.designViews.embroidary.findIndex(
          (b: { view: string }) => {
            return b.view === obj.view;
          }
        );
        const updatedState = { ...obj };
        if (indexU !== -1) {
          return {
            ...prevState,
            designViews: {
              ...prevState.designViews,
              embroidary: [
                ...prevState.designViews.embroidary.slice(0, indexU),
                updatedState,
                ...prevState.designViews.embroidary.slice(indexU + 1),
              ],
            },
          };
        }
      },
      () => {
        /* if (from) {
          const side = this.state.designViews.embroidary.find((i: any) => {
            return i.value === false || i.value === "false";
          });
          if (side) {
            this.savePreview(
              this.state.designerViewState + "_" + this.state.selectedSide,
              () => {
                this.setState({
                  selectedImage: (this.state.selectedColor?.images as any)[
                    side.view
                  ],
                  selectedSide: side.view,
                }, () => {
                  console.log("callBack :", callBack);
                  if(typeof callBack == "function"){
                    callBack();
                  }
                });
              }
            );
          }
          if (
            this.state.designViews["embroidary"].every(
              (i: any) => i.value === true
            )
          ) {
            // calculate total cart price 
            const { cartData } = this.props.cartData;
            console.log("cartData before savePreview", cartData);
            let grandTotalPrice =
              cartData.sizeQtyPageTotalPrice +
              cartData.appliqueFrontPageTotalPrice +
              cartData.appliqueBackPageTotalPrice +
              cartData.embroidaryFrontPageTotalPrice +
              cartData.embroidaryBackPageTotalPrice +
              cartData.embroidaryLeftPageTotalPrice +
              cartData.embroidaryRightPageTotalPrice + 
              cartData.embroidaryLeftCuffPageTotalPrice + 
              cartData.embroidaryRightCuffPageTotalPrice + 
              cartData.embroidaryCollarPageTotalPrice + 
              cartData.embroidaryCollarFlippedUpPageTotalPrice + 
              cartData.embroidaryHoodPageTotalPrice + 
              cartData.embroidaryHoodForwardPageTotalPrice + 
              cartData.embroidaryHoodBackwardPageTotalPrice + 
              cartData.totalPrice;
            console.log("grandTotalPrice : ", grandTotalPrice);
            this.props.updatePriceToCart({
              totalPrice: grandTotalPrice,
            });

            this.savePreview(
              this.state.designerViewState + "_" + this.state.selectedSide,
              () => {
                this.confirmModalOpen(
                  <>
                    
                    <TextTheta>
                      I have reviewed and hereby confirmed that my customization are
                      correct in the Order Summary, located to the right of this page.
                      The order will be produced using our current process. Your MUST
                      notify us if you are trying to match a previous order or something
                      made by another company.
                    </TextTheta>
                    <br />
                    <TextTheta>
                      <b>Please review your order carefully and You can’t edit it once Submitted</b>
                    </TextTheta>
                    <br />
                    
                    <Divider></Divider>
                    <CtaContainer>
                      <ClearStepBtn onClick={()=> this.confirmModalClose()}>Cancel</ClearStepBtn>
                      <NextpBtn onClick={() => {
                        this.addToCartAPI();
                        this.confirmModalClose();
                      }}>Submit</NextpBtn>
                    </CtaContainer>
                    
                  </>
                );

                console.log("after updating grandTotalPrice : ", this.props.cartData);

                //this.addToCartAPI();
                
                //this.clearPreview();
              }
            );
          }
        } */
      }
    );
  };

  cartAddedData = () => {
    console.log("ere");
  };

  applicableDesignAttributes = (callBack = () => {}) => {
    let selectedImages = this.state.selectedColor
                  ? this.state.selectedColor.images
                  : this.state.selectedProduct?.colors[0].images;
    /* update design attributes */
    let designAtrributes :any = {
      front: {
        [DesignerViewStates.Applique]: [
          { settingName: "contentText", settingVal: { value: "" } },
        ],
        [DesignerViewStates.EmboideryLocations]: [],
      },
      back: {
        [DesignerViewStates.Applique]: [
          { settingName: "contentText", settingVal: { value: "" } },
        ],
        [DesignerViewStates.EmboideryLocations]: [],
      },
    };
    this.state.applicableEmbroidaries.map(({previewSideName, order}:{previewSideName: string, order: number}) => {
      designAtrributes[previewSideName] = {
        [DesignerViewStates.Applique]: [
          { settingName: "contentText", settingVal: { value: "" } },
        ],
        [DesignerViewStates.EmboideryLocations]: []
      };

      /* if(previewSideName == "collar" && selectedImages && selectedImages.collar_flipped_up != ""){
        designAtrributes["collar_flipped_up"] = {
          [DesignerViewStates.Applique]: [
            { settingName: "contentText", settingVal: { value: "" } },
          ],
          [DesignerViewStates.EmboideryLocations]: []
        };
      } */
    });
    /* console.log("designAtrributes : ", designAtrributes); */
    
    this.setState({
      designAtrributes
    }, callBack);
  }

  applicableDesignViews = (callBack = () => {}) => {
    let selectedImages = this.state.selectedColor
                  ? this.state.selectedColor.images
                  : this.state.selectedProduct?.colors[0].images;
    let embroidaryViews: any = [];
    this.state.applicableEmbroidaries.map(({previewSideName, order}:{previewSideName: string, order: number}) => {
      embroidaryViews.push({
        view: previewSideName,
        value: false
      });
      /* if(previewSideName == "collar" && selectedImages && selectedImages.collar_flipped_up != ""){
        embroidaryViews.push({
          view: "collar_flipped_up",
          value: false
        });
      } */
    });

    let designViews = {
      ...this.state.designViews,
      embroidary: embroidaryViews
    };
    /* console.log("designViews : ", designViews); */

    this.setState((prevState) => {
      return {
        ...prevState,
        designViews: designViews
      }
    }, callBack);
  }

  convertToPreviewSideValue(side: string) {
    let sideVal = side.trim().toLowerCase().replace(/\s/g, "_");
    //console.log("sideVal : ", sideVal);
    let actualSideVal = "";
    let rank = 0;
    switch (sideVal) {
      case "front":
        actualSideVal = "front";
        rank = 1;
        break;
      case "back":
        actualSideVal = "back";
        rank = 5;
        break;
      case "chest":
        actualSideVal = "front";
        rank = 2;
        break;
      case "left_chest":
        actualSideVal = "front";
        rank = 3;
        break;
      case "right_chest":
        actualSideVal = "front";
        rank = 4;
        break;
      case "left_arm":
        actualSideVal = "arm_left";
        rank = 6;
        break;
      case "right_arm":
        actualSideVal = "arm_right";
        rank = 7;
        break;
      case "left_cuff":
        actualSideVal = "cuff_left";
        rank = 8;
        break;
      case "right_cuff":
        actualSideVal = "cuff_right";
        rank = 9;
        break;
      case "on_collar":
        actualSideVal = "on_collar";
        rank = 10;
        break;        
      case "collar_flipped_up":
        actualSideVal = "collar_flipped_up";
        rank = 11;
        break;
      case "hood":
        actualSideVal = "hood";
        rank = 12;
        break;   
      case "hood_forward":
        actualSideVal = "hood_forward";
        rank = 13;
        break;        
      case "hood_backward":
        actualSideVal = "hood_backward";
        rank = 14;
        break;
    }

    return {
      previewSideName : actualSideVal,
      order: rank
    };
  }

  embroidaryOptionList = (selectedProduct:any) => {
    let selectedImages = this.state.selectedColor
                  ? this.state.selectedColor.images
                  : selectedProduct?.colors[0].images;
    
    /* convert to previewSide names */
    let filteredEmbriodaries = selectedProduct.embroidaryId.filter((embroidary:any) => {
      let embdInfo = this.convertToPreviewSideValue(embroidary.location);
      //console.log(`selectedImages[${embdInfo.previewSideName}] : `, selectedImages[embdInfo.previewSideName]);
      return (selectedImages && selectedImages[embdInfo.previewSideName] && selectedImages[embdInfo.previewSideName] != "" && embroidary.name !== "Collar")?true:false;
    });
    
    let applicableEmbroidaries = filteredEmbriodaries.map((embroidary:any) => {
      let embdInfo = this.convertToPreviewSideValue(embroidary.location);
      return {
        ...embroidary,
        previewSideName: embdInfo.previewSideName,
        order: embdInfo.order
      };
    });

    let embdNewOptions = [];
    for (let t of selectedProduct.embroidaryId) {
      /* 
        {
          "_id": "63a5aca1c936f198fbf53cde",
          "name": "Collar",
          "images": [
              "on_collar",
              "collar_flipped_up"
          ],
          "keys": {
              "On_Collar": [
                  {
                      "label": "Font Style",
                      "name": "fontStyles",
                      "inputType": "select",
                      "params": "fontStyle"
                  },
                  {
                      "label": "Font Size",
                      "name": "fontSize",
                      "inputType": "select",
                      "params": "fontSize"
                  },
                  {
                      "label": "Thread Color",
                      "name": "threadToMatchs",
                      "inputType": "select",
                      "params": "threadColor"
                  },
                  {
                      "label": "Text line1",
                      "name": "textLine1",
                      "inputType": "text"
                  }
              ],
              "On_Collar_Flipper_Up": [
                  {
                      "label": "Font Style",
                      "name": "fontStyles",
                      "inputType": "select",
                      "params": "fontStyle"
                  },
                  {
                      "label": "Font Size",
                      "name": "fontSize",
                      "inputType": "select",
                      "params": "fontSize"
                  },
                  {
                      "label": "Thread Color",
                      "name": "threadToMatchs",
                      "inputType": "select",
                      "params": "threadColor"
                  },
                  {
                      "label": "Text line1",
                      "name": "textLine1",
                      "inputType": "text"
                  }
              ]
          },
          "basePrice": [
              {
                  "name": "On_Collar",
                  "price": 5,
                  "currency": "$"
              },
              {
                  "name": "On_Collar_Flipper_Up",
                  "price": 5,
                  "currency": "$"
              }
          ],
          "createdAt": "2023-07-10T20:01:03.684Z",
          "updatedAt": "2023-07-10T20:01:03.684Z",
          "__v": 0,
          "location": "Collar"
      }
      */

      let embdI = this.convertToPreviewSideValue(t.location);

      /* add special instruction to all */
      const index = applicableEmbroidaries.findIndex((i: any) => {
        return i.previewSideName === embdI.previewSideName && i.name == "Special Instructions";
      });
      if (index === -1 && t.name != "Collar") {
        let len = applicableEmbroidaries.length;
        let t0 = {
          "_id": "63a5aca1c936f198fbf53cd"+len,
          "name": "Special Instructions"+" "+embdI.previewSideName.replace("_"," ").toUpperCase(),
          "images": [
            
          ],
          "keys": {
              
          },
          "basePrice": [
              
          ],
          "value": "",
          "createdAt": "2023-07-10T20:01:03.684Z",
          "updatedAt": "2023-07-10T20:01:03.684Z",
          "__v": 0,
          "location": t.location,
          "previewSideName": embdI.previewSideName,
          "order": (len+1)
        };
        applicableEmbroidaries.push(t0);
      }

      /* devide the properties if name is Collar [Collar & Collar Flipper Up] */
      if(t.name == "Collar" && selectedImages && selectedImages.on_collar != "" && selectedImages.collar_flipped_up == ""){
        let len = applicableEmbroidaries.length;
        let t0 = {
          "_id": "63a5aca1c936f198fbf53cd"+len,
          "name": "Special Instructions ON COLLAR",
          "images": [
            
          ],
          "keys": {
              
          },
          "basePrice": [
              
          ],
          "value": "",
          "createdAt": "2023-07-10T20:01:03.684Z",
          "updatedAt": "2023-07-10T20:01:03.684Z",
          "__v": 0,
          "location": "On Collar",
          "previewSideName": "on_collar",
          "order": (len+1)
        };
        applicableEmbroidaries.push(t0);

        let t1 = {
          "_id": "63a5aca1c936f198fbf53cde",
          "name": "Collar",
          "images": [
              "on_collar"
          ],
          "keys": {
              "On_Collar": [
                  {
                      "label": "Font Style",
                      "name": "fontStyles",
                      "inputType": "select",
                      "params": "fontStyle"
                  },
                  {
                      "label": "Font Size",
                      "name": "fontSize",
                      "inputType": "select",
                      "params": "fontSize"
                  },
                  {
                      "label": "Thread Color",
                      "name": "threadToMatchs",
                      "inputType": "select",
                      "params": "threadColor"
                  },
                  {
                      "label": "Text line1",
                      "name": "textLine1",
                      "inputType": "text"
                  }
              ]
          },
          "basePrice": [
              {
                  "name": "On_Collar",
                  "price": 5,
                  "currency": "$"
              }
          ],
          "createdAt": "2023-07-10T20:01:03.684Z",
          "updatedAt": "2023-07-10T20:01:03.684Z",
          "__v": 0,
          "location": "On Collar",
          "previewSideName": "on_collar",
          "order": (len+2)
        };
        applicableEmbroidaries.push(t1);
      } else if(t.name == "Collar" && selectedImages && selectedImages.on_collar == "" && selectedImages.collar_flipped_up != ""){
        let len = applicableEmbroidaries.length;
        let t0 = {
          "_id": "63a5aca1c936f198fbf53cd"+len,
          "name": "Special Instructions COLLAR FLIPPED UP",
          "images": [
            
          ],
          "keys": {
              
          },
          "basePrice": [
              
          ],
          "value": "",
          "createdAt": "2023-07-10T20:01:03.684Z",
          "updatedAt": "2023-07-10T20:01:03.684Z",
          "__v": 0,
          "location": "Collar Flipper Up",
          "previewSideName": "collar_flipped_up",
          "order": (len+1)
        };
        applicableEmbroidaries.push(t0);

        let t2 = {
          "_id": "63a5aca1c936f198fbf53fgh",
          "name": "Collar Flipper Up",
          "images": [
              "collar_flipped_up"
          ],
          "keys": {
              "On_Collar_Flipper_Up": [
                  {
                      "label": "Font Style",
                      "name": "fontStyles",
                      "inputType": "select",
                      "params": "fontStyle"
                  },
                  {
                      "label": "Font Size",
                      "name": "fontSize",
                      "inputType": "select",
                      "params": "fontSize"
                  },
                  {
                      "label": "Thread Color",
                      "name": "threadToMatchs",
                      "inputType": "select",
                      "params": "threadColor"
                  },
                  {
                      "label": "Text line1",
                      "name": "textLine1",
                      "inputType": "text"
                  }
              ]
          },
          "basePrice": [
            {
                "name": "On_Collar_Flipped_Up",
                "price": 5,
                "currency": "$"
            }
          ],
          "createdAt": "2023-07-10T20:01:03.684Z",
          "updatedAt": "2023-07-10T20:01:03.684Z",
          "__v": 0,
          "location": "Collar Flipped Up",
          "previewSideName": "collar_flipped_up",
          "order": (len+2)
        };
        applicableEmbroidaries.push(t2);
      } else if(t.name == "Collar" && selectedImages && selectedImages.on_collar != "" && selectedImages.collar_flipped_up != ""){
        let len = applicableEmbroidaries.length;
        let t0 = {
          "_id": "63a5aca1c936f198fbf53cd"+len,
          "name": "Special Instructions ON COLLAR",
          "images": [
            
          ],
          "keys": {
              
          },
          "basePrice": [
              
          ],
          "value": "",
          "createdAt": "2023-07-10T20:01:03.684Z",
          "updatedAt": "2023-07-10T20:01:03.684Z",
          "__v": 0,
          "location": "On Collar",
          "previewSideName": "on_collar",
          "order": (len+1)
        };
        applicableEmbroidaries.push(t0);

        let t1 = {
          "_id": "63a5aca1c936f198fbf53cde",
          "name": "On Collar",
          "images": [
              "on_collar"
          ],
          "keys": {
              "On_Collar": [
                  {
                      "label": "Font Style",
                      "name": "fontStyles",
                      "inputType": "select",
                      "params": "fontStyle"
                  },
                  {
                      "label": "Font Size",
                      "name": "fontSize",
                      "inputType": "select",
                      "params": "fontSize"
                  },
                  {
                      "label": "Thread Color",
                      "name": "threadToMatchs",
                      "inputType": "select",
                      "params": "threadColor"
                  },
                  {
                      "label": "Text line1",
                      "name": "textLine1",
                      "inputType": "text"
                  }
              ]
          },
          "basePrice": [
              {
                  "name": "On_Collar",
                  "price": 5,
                  "currency": "$"
              }
          ],
          "createdAt": "2023-07-10T20:01:03.684Z",
          "updatedAt": "2023-07-10T20:01:03.684Z",
          "__v": 0,
          "location": "On Collar",
          "previewSideName": "on_collar",
          "order": (len+2)
        };

        let t3 = {
          "_id": "63a5aca1c936f198fbf53cd"+(len+1),
          "name": "Special Instructions COLLAR FLIPPED UP",
          "images": [
            
          ],
          "keys": {
              
          },
          "basePrice": [
              
          ],
          "value": "",
          "createdAt": "2023-07-10T20:01:03.684Z",
          "updatedAt": "2023-07-10T20:01:03.684Z",
          "__v": 0,
          "location": "Collar Flipped Up",
          "previewSideName": "collar_flipped_up",
          "order": (len+3)
        };
        applicableEmbroidaries.push(t3);

        let t4 = {
          "_id": "63a5aca1c936f198fbf53fgh",
          "name": "Collar Flipped Up",
          "images": [
              "collar_flipped_up"
          ],
          "keys": {
              "On_Collar_Flipped_Up": [
                  {
                      "label": "Font Style",
                      "name": "fontStyles",
                      "inputType": "select",
                      "params": "fontStyle"
                  },
                  {
                      "label": "Font Size",
                      "name": "fontSize",
                      "inputType": "select",
                      "params": "fontSize"
                  },
                  {
                      "label": "Thread Color",
                      "name": "threadToMatchs",
                      "inputType": "select",
                      "params": "threadColor"
                  },
                  {
                      "label": "Text line1",
                      "name": "textLine1",
                      "inputType": "text"
                  }
              ]
          },
          "basePrice": [
            {
                "name": "On_Collar_Flipped_Up",
                "price": 5,
                "currency": "$"
            }
          ],
          "createdAt": "2023-07-10T20:01:03.684Z",
          "updatedAt": "2023-07-10T20:01:03.684Z",
          "__v": 0,
          "location": "Collar Flipped Up",
          "previewSideName": "collar_flipped_up",
          "order": (len+4)
        };

        
        applicableEmbroidaries.push(t1);
        applicableEmbroidaries.push(t4);
      } /* else {
        applicableEmbroidaries.push(t);
      } */
    }
    
    if(selectedProduct.typeId.type == "hooded" && selectedImages && selectedImages.hood != ""){
      let len = applicableEmbroidaries.length;
      let t0 = {
        "_id": "63a5aca1c936f198fbf53cd"+len,
        "name": "Special Instructions HOOD",
        "images": [
          
        ],
        "keys": {
            
        },
        "basePrice": [
            
        ],
        "value": "",
        "createdAt": "2023-07-10T20:01:03.684Z",
        "updatedAt": "2023-07-10T20:01:03.684Z",
        "__v": 0,
        "location": "Hood",
        "previewSideName": "hood",
        "order": (len+1)
      };
      applicableEmbroidaries.push(t0);
      let h = {
        "_id": "63a5aca1c936f198fbf43jjh",
        "name": "Hood",
        "images": [
            "hood_forward",
            "hood_backward"
        ],
        "keys": {
            "Hood_Forward": [
                {
                    "label": "Font Style",
                    "name": "fontStyles",
                    "inputType": "select",
                    "params": "fontStyle"
                },
                {
                    "label": "Font Size",
                    "name": "fontSize",
                    "inputType": "select",
                    "params": "fontSize"
                },
                {
                    "label": "Thread Color",
                    "name": "threadToMatchs",
                    "inputType": "select",
                    "params": "threadColor"
                },
                {
                    "label": "Text line1",
                    "name": "textLine1",
                    "inputType": "text"
                }
            ],
            "Hood_Backward": [
                {
                    "label": "Font Style",
                    "name": "fontStyles",
                    "inputType": "select",
                    "params": "fontStyle"
                },
                {
                    "label": "Font Size",
                    "name": "fontSize",
                    "inputType": "select",
                    "params": "fontSize"
                },
                {
                    "label": "Thread Color",
                    "name": "threadToMatchs",
                    "inputType": "select",
                    "params": "threadColor"
                },
                {
                    "label": "Text line1",
                    "name": "textLine1",
                    "inputType": "text"
                }
            ]
        },
        "basePrice": [
            {
                "name": "Hood_Forward",
                "price": 5,
                "currency": "$"
            },
            {
                "name": "Hood_Backward",
                "price": 5,
                "currency": "$"
            }
        ],
        "createdAt": "2023-07-10T20:01:03.684Z",
        "updatedAt": "2023-07-10T20:01:03.684Z",
        "__v": 0,
        "location": "Hood",
        "previewSideName": "hood",
        "order": (len+2)
      };

      applicableEmbroidaries.push(h);
    }

    /* if(selectedProduct.typeId.type == "hooded" && selectedImages && selectedImages.hood_forward != "" && selectedImages.hood_backward == ""){
      let e1 = {
        "_id": "63a5aca1c936f198fbf63cde",
        "name": "Hood Forward",
        "images": [
            "hood_forward"
        ],
        "keys": {
            "Hood_Forward": [
                {
                    "label": "Font Style",
                    "name": "fontStyles",
                    "inputType": "select",
                    "params": "fontStyle"
                },
                {
                    "label": "Font Size",
                    "name": "fontSize",
                    "inputType": "select",
                    "params": "fontSize"
                },
                {
                    "label": "Thread Color",
                    "name": "threadToMatchs",
                    "inputType": "select",
                    "params": "threadColor"
                },
                {
                    "label": "Text line1",
                    "name": "textLine1",
                    "inputType": "text"
                }
            ]
        },
        "basePrice": [
            {
                "name": "Hood_Forward",
                "price": 5,
                "currency": "$"
            }
        ],
        "createdAt": "2023-07-10T20:01:03.684Z",
        "updatedAt": "2023-07-10T20:01:03.684Z",
        "__v": 0,
        "location": "Hood Forward"
      };
      embdNewOptions.push(e1);
    } else if(selectedProduct.typeId.type == "hooded" && selectedImages && selectedImages.hood_forward == "" && selectedImages.hood_backward != ""){
      let e2 = {
        "_id": "63a5aca1c936f198fbf53fgh",
        "name": "Hood Backward",
        "images": [
            "hood_backward"
        ],
        "keys": {
            "Hood_Backward": [
                {
                    "label": "Font Style",
                    "name": "fontStyles",
                    "inputType": "select",
                    "params": "fontStyle"
                },
                {
                    "label": "Font Size",
                    "name": "fontSize",
                    "inputType": "select",
                    "params": "fontSize"
                },
                {
                    "label": "Thread Color",
                    "name": "threadToMatchs",
                    "inputType": "select",
                    "params": "threadColor"
                },
                {
                    "label": "Text line1",
                    "name": "textLine1",
                    "inputType": "text"
                }
            ]
        },
        "basePrice": [
          {
              "name": "Hood_Backward",
              "price": 5,
              "currency": "$"
          }
        ],
        "createdAt": "2023-07-10T20:01:03.684Z",
        "updatedAt": "2023-07-10T20:01:03.684Z",
        "__v": 0,
        "location": "Hood Backward"
      };
      embdNewOptions.push(e2);
    } else if(selectedProduct.typeId.type == "hooded" && selectedImages && selectedImages.hood_forward != "" && selectedImages.hood_backward != ""){
      let e1 = {
        "_id": "63a5aca1c936f198fbf63cde",
        "name": "Hood Forward",
        "images": [
            "hood_forward"
        ],
        "keys": {
            "Hood_Forward": [
                {
                    "label": "Font Style",
                    "name": "fontStyles",
                    "inputType": "select",
                    "params": "fontStyle"
                },
                {
                    "label": "Font Size",
                    "name": "fontSize",
                    "inputType": "select",
                    "params": "fontSize"
                },
                {
                    "label": "Thread Color",
                    "name": "threadToMatchs",
                    "inputType": "select",
                    "params": "threadColor"
                },
                {
                    "label": "Text line1",
                    "name": "textLine1",
                    "inputType": "text"
                }
            ]
        },
        "basePrice": [
            {
                "name": "Hood_Forward",
                "price": 5,
                "currency": "$"
            }
        ],
        "createdAt": "2023-07-10T20:01:03.684Z",
        "updatedAt": "2023-07-10T20:01:03.684Z",
        "__v": 0,
        "location": "Hood Forward"
      };

      let e2 = {
        "_id": "63a5aca1c936f198fbf53fgh",
        "name": "Hood Backward",
        "images": [
            "hood_backward"
        ],
        "keys": {
            "Hood_Backward": [
                {
                    "label": "Font Style",
                    "name": "fontStyles",
                    "inputType": "select",
                    "params": "fontStyle"
                },
                {
                    "label": "Font Size",
                    "name": "fontSize",
                    "inputType": "select",
                    "params": "fontSize"
                },
                {
                    "label": "Thread Color",
                    "name": "threadToMatchs",
                    "inputType": "select",
                    "params": "threadColor"
                },
                {
                    "label": "Text line1",
                    "name": "textLine1",
                    "inputType": "text"
                }
            ]
        },
        "basePrice": [
          {
              "name": "Hood_Backward",
              "price": 5,
              "currency": "$"
          }
        ],
        "createdAt": "2023-07-10T20:01:03.684Z",
        "updatedAt": "2023-07-10T20:01:03.684Z",
        "__v": 0,
        "location": "Hood Backward"
      }; 

      
      embdNewOptions.push(e1);
      embdNewOptions.push(e2);
    }*/

    /* sort by order */
    applicableEmbroidaries = applicableEmbroidaries.sort((a:any, b:any) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    /* sort by alphabetically */
    applicableEmbroidaries = applicableEmbroidaries.sort(function(a:any, b:any){
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    });

    /* console.log("embdOptionsWithOrders : ", applicableEmbroidaries); */
    return applicableEmbroidaries;
  }

  confirmModalClose = () => {
    this.setState({ isConfirmModalOpen: false, confirmModalHTML: "" });
  };
  confirmModalOpen = (html: any) => {
    this.setState({ isConfirmModalOpen: true, confirmModalHTML: html });
  };

  loginModalClose = (callBack = () => {}) => {
    this.setState({ isLoginModalOpen: false, loginModalHTML: "" }, () => {
      if(typeof callBack === "function"){ 
        callBack();
      }
    });
  };
  loginModalOpen = (html: any) => {
    this.setState({ isLoginModalOpen: true, loginModalHTML: html });
  };

  signupModalClose = (callBack = () => {}) => {
    this.setState({ isSignUpModalOpen: false, signupModalHTML: "" }, () => {
      if(typeof callBack === "function"){ 
        callBack();
      }
    });
  };
  signupModalOpen = (html: any) => {
    this.setState({ isSignUpModalOpen: true, signupModalHTML: html });
  };

  confirmationModalCall(){
    this.confirmModalOpen(
      <>
        
        <TextTheta>
          I have reviewed and hereby confirmed that my customization are
          correct in the Order Summary, located to the right of this page.
          The order will be produced using our current process. Your MUST
          notify us if you are trying to match a previous order or something
          made by another company.
        </TextTheta>
        <br />
        <TextTheta>
        <p style={{fontSize: '16px'}}>Special Instruction:</p>
        </TextTheta>
        <textarea 
        style={{ 
          width: "100%",
          maxWidth: "100%",
          minHeight: "100px",
          resize: "vertical",
          boxSizing: "border-box",
        }}
        onChange={(e) => this.props.addSpecialInstructionToCart(e.target.value.trim())} rows={5} cols={49}></textarea>
        <TextTheta>
          <p><b>Please review your order carefully</b></p>
          <p style={{fontSize: '24px', 
          whiteSpace: "normal",  
          wordWrap: "break-word",
          width: "100%", 
          lineHeight: "1.5",
          margin: "0", }}>
            You cannot edit once it is submitted.
            </p>
        </TextTheta>
        <br />
        
        <Divider></Divider>
        <CtaContainer>
          <ClearStepBtn onClick={()=> this.confirmModalClose()}>Cancel</ClearStepBtn>
          <NextpBtn onClick={() => {
            this.addToCartAPI();
            this.confirmModalClose();
          }}>Submit</NextpBtn>
        </CtaContainer>
        
      </>
    )
  }

  handleLogin(params: ILoginRequestParams) {
    authClient
      .loginUser(params)
      .then((res: LoginResponse) => {
        console.log(res.data);
        if (res?.data?.token) {
          toast.success({ message: res.message });
          if(getCookie(authCookieName)){
            deleteCookie(authCookieName);
          }
          setCookie(authCookieName, res.data.token, 356);
          //document.cookie = `${authCookieName}=${res.data.token};`;

          if (localStorage.getItem("userDetails") != null) {
            localStorage.removeItem("userDetails");
          }

          localStorage.setItem("userDetails", JSON.stringify(res.data.user));
          //window.history.back();
          
          this.loginModalClose(() => {
            /* let myaccountMenu = document.getElementById("myaccount_menu");
            if(myaccountMenu != null){
              myaccountMenu.innerHTML = `<a color="#ffffff" href="#" class="sc-eKtvVk lbxynK"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" id="person"><path fill="#ffffff" d="M11.5,8 C12.3284271,8 13,8.67157288 13,9.5 L13,10 C13,11.9714437 11.14049,14 8,14 C4.85950997,14 3,11.9714437 3,10 L3,9.5 C3,8.67157288 3.67157288,8 4.5,8 L11.5,8 Z M11.5,9 L4.5,9 C4.22385763,9 4,9.22385763 4,9.5 L4,10 C4,11.4376472 5.4321567,13 8,13 C10.5678433,13 12,11.4376472 12,10 L12,9.5 C12,9.22385763 11.7761424,9 11.5,9 Z M8,1.5 C9.51878306,1.5 10.75,2.73121694 10.75,4.25 C10.75,5.76878306 9.51878306,7 8,7 C6.48121694,7 5.25,5.76878306 5.25,4.25 C5.25,2.73121694 6.48121694,1.5 8,1.5 Z M8,2.5 C7.03350169,2.5 6.25,3.28350169 6.25,4.25 C6.25,5.21649831 7.03350169,6 8,6 C8.96649831,6 9.75,5.21649831 9.75,4.25 C9.75,3.28350169 8.96649831,2.5 8,2.5 Z"></path></svg><div class="sc-oBxuN cRGLyd" style="width: 106px;"><a color="#142F8F" href="/my-profile" class="sc-eKtvVk bcRUjt">My Profile</a><br><a color="#142F8F" href="/my-orders" class="sc-eKtvVk bcRUjt">My Orders</a><br><a color="#142F8F" href="javascript:void(0)" class="sc-eKtvVk bcRUjt">Logout</a></div></a>`;
            } */
          });

          this.confirmationModalCall();
        } else {
          toast.error({
            message: "Log in failed. Please try again",
            delay: 10000,
          });
        }
      })
      .catch((e) => {
        //console.log("🚀 ~ file: SignUp.page.tsx:46 ~ handleSignUp ~ e", e);
        let err = JSON.parse(e.metadata.apiErrorMessage); 
        //toast.error({ message: "Log in failed.", delay: 10000 });
        toast.error({ title: err.message, description: err.error, delay: 10000 });
      });
  }

  handleSignUp(params: ISignUpRequestParams) {
    authClient
      .signup(params)
      .then((res: ISignupResponse) => {
        //this.props.saveAuthDetails(res.data);
        /* login the new user */
        this.handleLogin({
          email: params.email,
          password: params.password
        });

        toast.message({
          title: "Sign up was successful !",
          /*description: "To login, click here!",
          actionLabel: "Login",
          actionHandler: () => {
            window.location.pathname = "/login";
          },*/
          delay: 3000,
        });

        /* setTimeout(() => {
          window.location.pathname = "/";
        }, 3000); */
      })
      // .catch((e) => {
      //   // "🚀 ~ file: SignUp.page.tsx:46 ~ handleSignUp ~ e",
      //   console.log( e);
      //   const errorMessage = e?.response?.data?.error || "Sign up failed.";
      //   toast.error({
      //     message: errorMessage,
      //     delay: 10000,
      //   });
      // });
      .catch((e) => {
        //console.log("🚀 ~ file: SignUp.page.tsx:46 ~ handleSignUp ~ e", e);
        let err = JSON.parse(e.metadata.apiErrorMessage); 
        toast.error({ title: err.message, description: err.error, delay: 10000 });
      });
  }

  setIsLoginModalVisible(val:any){
    this.setState({
      isLoginModalVisible: val
    }); 
  }

  handleLogiOrSignUpPopup(){
    const {isLoginModalVisible} = this.state;

    if(isLoginModalVisible){
        this.loginModalOpen(
          <>
            <LoginForm handleLogin={this.handleLogin} />
            <a onClick={() => { 
              this.setIsLoginModalVisible(false);
              this.signupModalOpen(
                <>
                  <SignUpForm handleSignUp={this.handleSignUp} />
                  <a onClick={() => {
                    this.setIsLoginModalVisible(true);
                    this.setState({ isLoginModalOpen: true });
                  }} style={{cursor:"pointer", float:"left", marginTop:"15px", color:"blue"}}>Login</a>
                </>
              );
            }} style={{cursor:"pointer", float:"left", marginTop:"15px", color:"blue"}}>Signup</a>
            <a onClick={() => {
              window.open('/forget-password');
            }} style={{cursor:"pointer", float:"right", marginTop:"15px", color:"blue"}}>Forgot Password</a>
          </>
        );
    } else {
      this.signupModalOpen(
        <>
          <SignUpForm handleSignUp={this.handleSignUp} />
          <a onClick={() => {
            this.setIsLoginModalVisible(true);
            this.setState({ isLoginModalOpen: true });
          }} style={{cursor:"pointer", float:"left", marginTop:"15px", color:"blue"}}>Login</a>
        </>
      )
    }
  }

  render() {
    const {
      designAtrributes,
      designerViewState,
      selectedProduct,
      selectedImage,
      selectedSide,
      selectedColor,
      selectedSizes,
      screenLoader,
      applicableEmbroidaries,
      editCartProduct,
      selectedApplique
    } = this.state;
    const { fontOptions } = this.props;
    //console.log(designAtrributes, "designAtrributes");
    return (
      <Container>
        {selectedProduct && (
          <>
            {screenLoader && (
              <LoaderOverlay>
                <LoaderContainer>
                  <Loader size="large"></Loader>
                </LoaderContainer>
              </LoaderOverlay>
            )}
            <SidePreview
              images={
                selectedColor
                  ? selectedColor?.images
                  : selectedProduct?.colors[0]?.images
              }
              selectedSide={selectedSide}
              applicableEmbroidaries={applicableEmbroidaries}
              onSelect={this.changeSide}
              designerViewState={designerViewState}
              selectedProduct={selectedProduct}
              onlyFrontEnabled={
                designerViewState !== DesignerViewStates.EmboideryLocations
                // designerViewState === DesignerViewStates.Applique ||
                // designerViewState === DesignerViewStates.EmboideryLocations
              }
            />
            {selectedImage? 
          <PreviewContainer>
             <Preview
                svgRef={this.svgRef}
                updatePreview={this.updatePreview}
                designAtrributes={designAtrributes[selectedSide]}
                image={ 
                  selectedImage ||
                  (selectedProduct?.colors[0]?.images as any)[selectedSide]
                }
                selectedSide={selectedSide}
                fontOptions={fontOptions?fontOptions:[]}
                selectedProduct={selectedProduct}
                selectedApplique={selectedApplique}
              />
            </PreviewContainer>:''}
            <SettingsContainer>
              <SettingsCard fullWidth={false}>
                {designerViewState ===
                  DesignerViewStates.AppliqueTypeSelector && (
                  <AppliqueSelector
                    onBack={() => {
                      this.setState({
                        designerViewState: DesignerViewStates.ColorAndQuantity,
                      });
                    }}
                    onNext={(selectedApplique) => {
                      this.setState({
                        designerViewState: DesignerViewStates.Applique,
                        selectedApplique,
                        selectedImage: (selectedColor?.images as any)[
                          "front"
                        ],
                        selectedSide: "front",
                      });
                    }}
                    appliqueId={selectedProduct?.appliqueId || []}
                    editCartProduct={editCartProduct}
                  />
                )}

                {designerViewState === DesignerViewStates.ColorAndQuantity &&
                  selectedColor && (
                    <ColorSizeAndQuantitySetting
                      selectedSizes={selectedSizes}
                      selectedProduct={selectedProduct}
                      editCartProduct={editCartProduct}
                      onNext={(selectedSizes, colorObj, obj) => {
                        this.changeViewState(
                          DesignerViewStates.AppliqueTypeSelector
                        );
                        this.props.addColorToCart(colorObj);
                        this.props.updateSizeQtyPriceToCart(obj);
                        this.props.removePriceToCart({price : this.props.cartData.sizeQtyPageTotalPrice?this.props.cartData.sizeQtyPageTotalPrice:obj.price});
                        this.props.addPriceToCart(obj);
                        this.props.updateQuantityToCart(obj);
                        this.setState({
                          selectedSizes,
                        });
                      }}
                      onColorChange={this.selectColor}
                      selectedColorObj={selectedColor}
                      onBack={() => {}}
                    />
                  )}

                {designerViewState === DesignerViewStates.Applique && (
                  <Applique
                    updatePreview={this.updatePreview}
                    clearPreview={this.clearPreview}
                    cartAddedData={this.cartAddedData}
                    designViews={this.state.designViews}
                    updateAppliqueDesignViews={this.updateAppliqueDesignViews}
                    onNext={(appliqueId) => {
                      appliqueId["front"].name = (
                        this.state.selectedApplique as any
                      ).name;
                      appliqueId["front"]._id = (
                        this.state.selectedApplique as any
                      )._id;
                      /* console.log(
                        "updateAppliqueToCart(appliqueId) ----> ",
                        appliqueId
                      ); */
                      this.props.updateAppliqueToCart(appliqueId);
                      /* console.log(
                        "this.state.selectedSide ",
                        this.state.selectedSide
                      );
                      console.log("appliqueId", appliqueId);
                      console.log("name", appliqueId["front"].name); */

                      /* this.updateAppliqueDesignViews(
                        {
                          view: this.state.selectedSide,
                          value: true,
                        },
                        "next"
                      ); */

                      this.navViewUpdate(1);
                    }}
                    selectedSizes={selectedSizes}
                    selectedColor={selectedColor}
                    selectedSide={selectedSide}
                    onBack={() => {
                      // this.clearPreview();

                      /* this.changeViewState(
                        DesignerViewStates.AppliqueTypeSelector
                      ); */
                      this.navViewUpdate(0);
                    }}
                    selectedProduct={selectedProduct}
                    editCartProduct={editCartProduct}
                  />
                )}

                {designerViewState ===
                  DesignerViewStates.EmboideryLocations && (
                  <EmboideryLocations
                    updatePreview={this.updatePreview}
                    embroidaryOptions={this.embroidaryOptionList(selectedProduct)}
                    updateEmbroidaryDesignViews={
                      this.updateEmbroidaryDesignViews
                    }
                    selectedColor={this.state.selectedColor}
                    onNext={(
                      cartEmbroidaryData: Record<
                        string,
                        { emboidaryOption: EmbroidaryID; value: any }
                      >
                    ) => {
                      if(this.state.selectedSide == "collar_flipped_up"){
                        this.props.updateEmbdryCollarFlippedUpPriceToCart({
                          totalPrice: this.props.cartData.totalPrice
                        });
                      }

                      /* this.updateEmbroidaryDesignViews(
                        {
                          view: this.state.selectedSide,
                          value: true,
                        },
                        "next"
                      ); */

                      this.navViewUpdate(1);
                      
                      // if (
                      //   this.state.designViews["embroidary"].every(
                      //     (i: any) => i.value === true
                      //   )
                      // ) {
                      //   this.addToCartAPI();
                      // }

                      
                    }}
                    selectedSide={selectedSide}
                    designViews={this.state.designViews}
                    onBack={() => {
                      // this.clearPreview();

                      /* mark back as not-filled */
                      /*this.updateAppliqueDesignViews({
                        view: "front",
                        value: false,
                      });

                      this.updateAppliqueDesignViews({
                        view: "back",
                        value: false,
                      });*/

                      /* this.setState({
                        selectedImage: (
                          this.state.selectedColor?.images as any
                        )["front"],
                        selectedSide: "front",
                      });
                      this.changeViewState(DesignerViewStates.Applique); */

                      this.navViewUpdate(0);
                    }}
                    appliquePreviewData={
                      designAtrributes[selectedSide][
                        DesignerViewStates.Applique
                      ]
                    }
                    selectedProduct={selectedProduct}
                    editCartProduct={editCartProduct}
                  />
                )}

                {designerViewState === DesignerViewStates.Cart && (
                  <CartPreview
                    selectedProduct={selectedProduct}
                    changeViewState={this.changeViewState} 
                    setEditProduct={this.setEditProduct}
                    onNext={() => {
                      this.changeViewState(DesignerViewStates.Shipping);
                    }}
                    onBack={() => {
                      /* this.changeViewState(
                        DesignerViewStates.EmboideryLocations
                      ); */
                    }}
                  />
                )}

                {designerViewState === DesignerViewStates.Shipping && (
                  <Shipping
                    selectedProduct={selectedProduct}
                    onNext={() => {
                      // this.changeViewState(DesignerViewStates.Cart);
                    }}
                    onBack={() => {
                      this.changeViewState(DesignerViewStates.Cart);
                    }}
                  />
                )}
                <h5>*Colors, letter shapes and patterns may vary from actual product. This is for general idea preview only.</h5>
              </SettingsCard>
            </SettingsContainer>
            <FloaterMenu isSubmited={this.state.isFinalSubmission} changeViewState={this.changeViewState} designerViewState={designerViewState}/>
            {(localStorage.getItem("userDetails") != null && getCookie(authCookieName) != null)?<Modal
                footer={false}
                isOpen={this.state.isConfirmModalOpen}
                width={"25%"}
                close={this.confirmModalClose}
                iconClose={true}
                headerText={"Submit customize item?"}
                state={"warn"}
              >
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {this.state.confirmModalHTML}
                </div>
              </Modal>
            :
              <Modal
                footer={false}
                isOpen={this.state.isLoginModalVisible?this.state.isLoginModalOpen:this.state.isSignUpModalOpen}
                width={"25%"}
                close={this.state.isLoginModalVisible?this.loginModalClose:this.signupModalClose}
                iconClose={true}
                headerText={this.state.isLoginModalVisible?"Login":"Signup"}
                state={"warn"}
              >
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {this.state.isLoginModalVisible?this.state.loginModalHTML:this.state.signupModalHTML}
                </div>
              </Modal>
            }
          </>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 90vh;
  padding: ${paddingL};
  /* background-image: url(${Pattern}); */
  background-color: #f7f9fc;
  background-size: cover;
`;

const PreviewContainer = styled.div`
  flex: 1;
`;

const SettingsContainer = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
`;

const SettingsCard = styled(Card)`
  /* max-height: 6.9in; */
  height: 100%;
  min-height: ${designWindowHeight};
  overflow-y: scroll;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: calc(50vh - 150px);
  right: calc(50vw - 10px);
  z-index: 999999;
`;

const LoaderOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: #8080806b;
  z-index: 999999;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #e3e3e3;
  margin: ${marginM} 0;
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ClearStepBtn = styled.button`
  margin-right: ${marginS};
  background: #dde3e6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: ${paddingS} ${paddingM};
`;

const NextpBtn = styled.button`
  margin-right: ${marginS};
  background: ${primaryColor} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: ${paddingS} ${paddingM};
  color: #ffffff;
`;

const mapStateToProps = (state: IAppState) => {
  return {
    fontOptions: state.applique.appliqueOptions.fontStyleOptions,
    cartData: state.cart,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getAllOptions: () => appliqueClient.fetchAllOptions(dispatch),
    getProduct: (id: string) => categoryClient.getProductDetails(id),
    selectedProduct: (product: IOneProductV2) =>
      dispatch(updateProduct(product)),
    addColorToCart: (data: any) => dispatch(addColorToCart(data)),
    addPriceToCart: (data: any) => dispatch(addPriceToCart(data)),
    removePriceToCart: (data: any) => dispatch(removePriceToCart(data)),
    updatePriceToCart: (data: any) => dispatch(updatePriceToCart(data)),
    updateSizeQtyPriceToCart: (data: any) =>
      dispatch(updateSizeQtyPriceToCart(data)),
    updateEmbdryCollarFlippedUpPriceToCart: (data: any) =>
      dispatch(updateEmbdryCollarFlippedUpPriceToCart(data)),
    updateQuantityToCart: (data: any) => dispatch(updateQuantityToCart(data)),
    updateAppliqueToCart: (data: any) => dispatch(updateAppliqueToCart(data)),
    updatePreviewImagesToCart: (data: any) =>
      dispatch(updatePreviewImagesToCart(data)),
    updateProductIDToCart: (data: any) => dispatch(updateProductIDToCart(data)),
    addSpecialInstructionToCart: (data: any) => dispatch(addSpecialInstructionToCart(data))
  };
};

export const ShirtDesigner = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShirtDesignerComponent as any);
