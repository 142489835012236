export interface IValidationResult {
  isValid: boolean;
  errorMessage: string;
}

export class Validation {
  public static validateEmail(mail: string): IValidationResult {
    if (!mail?.length) {
      return {
        isValid: false,
        errorMessage: "Email is required",
      };
    }
    if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    return {
      isValid: false,
      errorMessage: "Email format is not correct",
    };
    return{
      isValid:true,
      errorMessage:""
    }
  }

  public static validateName(name: string): IValidationResult {
    if (!name?.length) {
      return {
        isValid: false,
        errorMessage: "Name is required",
      };
    }

    const regex = /^[A-Za-z]+ +[A-Za-z]+$/;

    if (!regex.test(name)) {
      return {
        isValid: false,
        errorMessage: "First and last name must be separated with at least one space",
      };
    }

    return {
      isValid: true,
      errorMessage: "",
    };
}
  public static validateToken(token: string): IValidationResult {
    if (!token?.length) {
      return {
        isValid: false,
        errorMessage: "Token is required",
      };
    }
    return {
      isValid: true,
      errorMessage: "",
    };
  }

  // public static validatePhone(phone: string): IValidationResult {
  //   if (!phone?.length) {
  //     return {
  //       isValid: false,
  //       errorMessage: "Phone is required",
  //     };
  //   }

  //   return {
  //     isValid:
  //       /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
  //         phone
  //       ),
  //     errorMessage: "",
  //   };
  // }

  public static validatePassword(password: string): IValidationResult {
    if (!password?.length) {
      return {
        isValid: false,
        errorMessage: "Password is required",
      };
    }

    if (password?.length < 6) {
      return {
        isValid: true,
        errorMessage: "password must be atleast 6 characters",
      };
    }

    return {
      isValid: true,
      errorMessage: "",
    };
  }
  public static validateConfirmPassword(password: string,confirm:string): IValidationResult {
    if (password!==confirm) {
      return {
        isValid: true,
        errorMessage: "confirm password must be same as new password",
      };
    }

    return {
      isValid: true,
      errorMessage: "",
    };
  }

  public static isValidUrl(expectedUrl: string) {
    try {
      new URL(expectedUrl);
    } catch {
      return false;
    }

    return true;
  }
}
