import styled from "styled-components";
import { primaryColor, themeWhite } from "../../constants";

export const Loader = styled.span`
  width: 54px;
  height: 54px;
  position: relative;
  border-radius: 4px;  /* To make the loader circular */
  display: block; 
  background-color: rgb(20, 47, 143);  /* Ensure background is transparent initially */
  background-image:
    radial-gradient(circle 5px,rgb(255, 254, 254) 100%, transparent 0),
    radial-gradient(circle 5px,rgb(255, 254, 254) 100%, transparent 0),
    radial-gradient(circle 5px,rgb(255, 254, 254) 100%, transparent 0),
    radial-gradient(circle 5px,rgb(255, 254, 254) 100%, transparent 0),
    radial-gradient(circle 5px,rgb(255, 254, 254) 100%, transparent 0),
    radial-gradient(circle 5px,rgb(255, 254, 254) 100%, transparent 0);
  background-repeat: no-repeat;
  animation: move 4s linear infinite, rotate 2s linear infinite;

  /* Optional: To ensure it’s visible on the page */
  margin: 0 auto;
  
  @keyframes rotate {
  0%, 20% { transform: rotate(0deg); }
  30%, 40% { transform: rotate(90deg); }
  50%, 60% { transform: rotate(180deg); }
  70%, 80% { transform: rotate(270deg); }
  90%, 100% { transform: rotate(360deg); }
}

@keyframes move {
  0%, 9% {
    background-position:
      -12px -15px, -12px 0px, -12px 15px,
      12px -15px, 12px 0px, 12px 15px;
  }
  10%, 25% {
    background-position:
      0px -15px, -12px 0px, -12px 15px,
      34px -15px, 12px 0px, 12px 15px;
  }
  30%, 45% {
    background-position:
      0px -34px, -12px -10px, -12px 12px,
      34px -15px, 12px -10px, 12px 12px;
  }
  50%, 65% {
    background-position:
      0px -34px, -12px -34px, -12px 12px,
      34px -12px, 0px -10px, 12px 12px;
  }
  70%, 85% {
    background-position:
      0px -34px, -12px -34px, 0px 12px,
      34px -12px, 0px -10px, 34px 12px;
  }
  90%, 100% {
    background-position:
      0px -34px, -12px -34px, 0px 0px,
      34px -12px, 0px 0px, 34px 12px;
  }
}
`;
export const Login = styled.button` 
color: ${themeWhite};
  background-color: ${primaryColor};
  border-radius: 25px;
  padding: 10px 15px;
  margin-top: 10px;
  height: 40px;
  font-size: 14px;
`;
export const Check = styled.div`
 display: inline-block;
  transform: rotate(45deg);
  height:24px;
  width: 12px;
  border-right: 7px solid #78b13f;
  border-bottom: 7px solid #78b13f;
`
export const Cross = styled.div`
 --c: #B90504;

  display:inline-block;
  height: 100px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 35px inset var(--c);
  border-image: conic-gradient(var(--c) 0 0) 50%/calc(50% - 1px);
  clip-path: circle(); /* remove this if you don't want a rounded shape */
  rotate: 45deg;
`