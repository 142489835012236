import React, { ChangeEvent, useEffect, useState } from "react";
import { IResetPasswordRequestParams } from "../../models";
import { Validation } from "../../Utils";

import { Button, StyledInput} from "./styled.component";
interface ResetPasswordFormProps {
    handleResetPassword(params: IResetPasswordRequestParams): void;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ handleResetPassword }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [ResetPasswordParam, setResetPasswordParams] = useState<IResetPasswordRequestParams>(
      {
        token: urlParams.get('token') || "",
        newpassword: "",
        confirmpassword: ""
    }
  );

  const [validationError, setValidationErrors] = useState({
    tokenInvalidMsg: "",
    newPasswordInvalidMsg: "",
    confirmPasswordInvalidMsg: "",
  });

  const validateForm = () => {
    const {token,newpassword,confirmpassword} = ResetPasswordParam;

    const { errorMessage: tokenInvalidMsg } = Validation.validateToken(token);
    const { errorMessage: newPasswordInvalidMsg } =
      Validation.validatePassword(newpassword);
    const { errorMessage: confirmPasswordInvalidMsg } = Validation.validateConfirmPassword(newpassword,confirmpassword);

    setValidationErrors({
        tokenInvalidMsg,
        newPasswordInvalidMsg,
        confirmPasswordInvalidMsg,
    });

    return (
      !tokenInvalidMsg &&
      !newPasswordInvalidMsg &&
      !confirmPasswordInvalidMsg 
      
    );
  };

  const validateAndResetPassword = () => {
    const { token, newpassword, confirmpassword } = ResetPasswordParam;

    if (validateForm()) {
      const ResetPasswordParams: IResetPasswordRequestParams = {
        token, 
        newpassword,
        confirmpassword
      };

      handleResetPassword(ResetPasswordParams);
    }
  };


  const handleTokenChange = (e: ChangeEvent<HTMLInputElement>) => {
    const token = e.target.value;
    setResetPasswordParams({ ...ResetPasswordParam, token });

    setValidationErrors({
      ...validationError,
      tokenInvalidMsg: Validation.validateToken(token).errorMessage,
    });
  };

  const handleNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newpassword= e.target.value;    
    setResetPasswordParams({ ...ResetPasswordParam, newpassword });

    setValidationErrors({
      ...validationError,
      newPasswordInvalidMsg: Validation.validatePassword(newpassword).errorMessage,
    });
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const confirmpassword = e.target.value;
    setResetPasswordParams({ ...ResetPasswordParam, confirmpassword });

    setValidationErrors({
      ...validationError,
      confirmPasswordInvalidMsg: Validation.validateConfirmPassword(confirmpassword,ResetPasswordParam.newpassword).errorMessage,
    });
  };

  return (
    <>
      <StyledInput
        type="text"
        placeholder="Enter Token *"
        value={ResetPasswordParam.token}
        autoComplete="off"
        onChange={handleTokenChange}
        label={validationError.tokenInvalidMsg}
      />

      <StyledInput
        type="password"
        placeholder="Enter Password *"
        autoComplete="off"
        onChange={handleNewPasswordChange}
        label={validationError.newPasswordInvalidMsg}
      />
      <StyledInput
        type="password"
        placeholder="Enter Passsword Again *"
        autoComplete="off"
        onChange={handleConfirmPasswordChange}
        label={validationError.confirmPasswordInvalidMsg}
      />
      <Button onClick={validateAndResetPassword}>Reset</Button>
    </>
  );
};
