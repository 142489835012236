import {
    Embroidary_Text_Style,
    GreekLetterSVGs
} from "../constants";

import {GREEK_ALPHABETS} from '../models';

export function pascalCase(str:string){
    return str.toString().replace(/\w+/g,function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();});
}

export const maskingPhone = (str : any) => {
    if(typeof str === "undefined") return "";
    
    let val = str.toString();
    val = val.replace(/ /gm, '');
    val = val.replace(/-/gm, '');
    val = val.replace(/[^0-9]/gm, '');
    console.log(val);
    val = val.substring(0, 10); // only 10 digits are allowed

    //let num = `${val.substring(0, 3)}-${val.substring(3, 6)}-${val.substring(6, val.length)}`;
    let numArr = [];
    if(val.substring(0, 3).length > 0){
        numArr.push(val.substring(0, 3));
    }
    if(val.substring(3, 6).length > 0){
        numArr.push(val.substring(3, 6));
    }
    if(val.substring(6, val.length).length > 0){
        numArr.push(val.substring(6, val.length));
    }

    let num = numArr.join("-");
    num = num.trim();

    return num;
}

export const displayEmbdName = (str : string) => {
    let cnvtStr = str;
    switch(str){
      case "arm_right": cnvtStr = "right_arm"; break;
      case "arm_left": cnvtStr = "left_arm"; break;
      case "cuff_right": cnvtStr = "right_cuff"; break;
      case "cuff_left": cnvtStr = "left_cuff"; break;
      //case "collar": cnvtStr = "collar"; break;
      //case "": cnvtStr = "left_arm"; break;
    }
    return cnvtStr.replace(/_/g, " ");
}

export function formatDate(dt: string) {
    const date = new Date(dt);
    const formatter = new Intl.DateTimeFormat('en-US', {
        /*weekday: 'long',*/
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        /*hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false     // setting 24 hour format */
    });
    const formattedDate = formatter.format(date);
    //console.log(formattedDate);
    return formattedDate;
}

export function upsideDown(d:string = "") {
    let n_string = "`_+\\/'\".,~{}?!|=()[]><-:abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ",
    u_string = " Z⅄XϺɅՈꓕSꓤꝹԀONꟽ⅂ꓘᒋIH⅁ᖵƎᗡϽꓭ∀zʎxʍʌnʇsɹbdouɯןʞſᴉɥƃɟǝpɔqɐ:-><[]()=|¡¿{}~❛˙„,\\/+‾,", // http://stackoverflow.com/a/2995356 (for the upside down UNICODE sequence)
    n = n_string.split(""),
    u = u_string.split("").reverse();

    let str:string = d; /* .toLowerCase() */
    let buffer:Array<string> = [];
    let result:Array<string> = [];
    if (/[a-zA-Z\u0020:\-><\[\]\(\)=\|!\?\{\}~,\.'"\/\\\+_`]/.test(str) && !(/[\d;&\^$#@\*]/g.test(str))) {
      buffer = str.split("");
      buffer.forEach(function (v) {
          n.forEach(function (nv, ni) {
              if (v === nv) {
                  result.push(u[ni]);
              }
          });
      });
      str = result.reverse().join(""); 
    } else {
      str = "";
    }

    return str;
};

export function flippedText(text:any){
    //let txt:string = text.replace(Embroidary_Text_Style.flipped, "");
    return Embroidary_Text_Style.flipped+text;
}

export function in_array(needle:string, haystack:Array<string>){
    var found = 0;
    for (var i=0, len=haystack.length;i<len;i++) {
        if (haystack[i] == needle) return i;
            found++;
    }
    return -1;
}

export function isNumberLetters(str: any){
    return /^\d+$/.test(str);
}

export function isGreekLetters(str: any){
    let greekLetters = Object.values(GREEK_ALPHABETS);
    let isGreekLetter = false;
    for(let s in str){
        if(in_array(str[s], greekLetters) !== -1){
            isGreekLetter = true;
        }
    }
    return isGreekLetter;
}

export function generateGreekLetterSVG(str: any){
    let greekLetters = Object.values(GREEK_ALPHABETS);
    let greekLetterSVGs = "";
    for(let s in str){
        let v = str[s];
        if(in_array(v, greekLetters) !== -1){
            greekLetterSVGs += GreekLetterSVGs[v] || "";
        }
    }
    return greekLetterSVGs;
}

export function generatePreviewSVG(str:any){
    return GreekLetterSVGs[str] || "";
}

// Set a cookie
export function setCookie(name: string, value: string, days: number) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // days to milliseconds
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// Get a cookie
export function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts: any[] = value.split(`; ${name}=`) || [];
    return (parts.length === 2) ? parts.pop().split(';').shift() : null;
}

// Delete a cookie
export function deleteCookie(name: string) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
}