import React, {
  useState,
  useEffect,
  ChangeEvent,
  ReactComponentElement,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormContainer,
  LeftSection,
  FormGroup,
  FormGroupColumn,
  RightSection,
  ButtonAlpha,
  AccordionContainer,
} from "./styled.component";

import { RadioButton, Textarea } from "@happeouikit/form-elements";
import { Input, Dropdown, Checkbox } from "@happeouikit/form-elements";

import { profileClient } from "../../ApiClients/ProfileClient";
import { toast } from "@happeouikit/toast";

import { TextZeta, BodyUI } from "@happeouikit/typography";
import { AddressForm } from "./address.form";
import { ProfileResponseDatum, IProfileAddressState } from "../../models";
import Accordion from "./Accordion";

import Table, { Center } from "../../components/Table";
import Edit from "../../components/svgIcons/Edit";
import Delete from "../../components/svgIcons/Delete";
import {maskingPhone} from "../../Utils/common";
import { Tooltip } from "@happeouikit/tooltip";

interface ProfileFormProps {}

export interface IProfileAddressDisplay {
  fullName: string;
  mobileNumber: string;
  addressLine1: string;
  addressLine2: string;
  landmark: string;
  city: string;
  state: string;
  pinCode: string;
  action: React.ReactNode | string;
}

export const ProfileForm: React.FC<ProfileFormProps> = ({}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");

  /* const [isShippingModalVisible, setIsShippingModalVisible] = useState(false); */
  const [isBillingModalVisible, setIsBillingModalVisible] = useState(false);

  const [selectedAccordian, setSelectedAccordian] = useState(1);
  const [selectedAddress, setSelectedAddress] = useState<any>(false);
  const [isDelete, setIsDelete] = useState(false);

  const dispatch = useDispatch();

  const profileData: any = useSelector<ProfileResponseDatum>(
    (state: any) => state.profile
  );

  const profileBillingTableData: any =
    useSelector<Array<IProfileAddressDisplay>>((state: any) =>
      state.profileAddress.billingAddresses.map(
        (billInfo: IProfileAddressState) => ({
          [` Full Name `]: billInfo.fullName,
          [` Phone Number `]: maskingPhone(billInfo.mobileNumber),
          /* [` DOB `]: billInfo.extension, */
          /* [` Address Type`]: billInfo?.address_type, */
          [` Address Line 1 `]: billInfo.addressLine1,
          [` Address Line 2 `]: billInfo.addressLine2,
          /* landmark: billInfo.landmark, */
          [` City `]: billInfo.city,
          [` State `]: billInfo.state,
          [` ZIP Code `]: billInfo.pinCode,
          [` Organization `]: billInfo.orgName,
          [` School `]: billInfo.schoolName,
          Action: (
            <>
              <a
                title="Edit"
                id="addr_edit"
                style={{ color: "Blue" }}
                onClick={() => editAddress(billInfo)}
                data-tooltip="Edit"
              >
                
                <Edit />
              
              </a>
              &nbsp; &nbsp;
              <a
                title="Delete"
                id="addr_del"
                style={{ color: "Red" }}
                onClick={() => deleteAddress(billInfo)}
                data-tooltip="Delete"
              >
                
                <Delete />
              </a>
            </>
          ),
        })
      )
    ) || [];

  /* const profileShippingTableData: any =
    useSelector<Array<IProfileAddressDisplay>>((state: any) =>
      state.profileAddress.shippingAddresses.map(
        (shipInfo: IProfileAddressState) => ({
          fullName: shipInfo.fullName,
          mobileNumber: shipInfo.mobileNumber,
          addressLine1: shipInfo.addressLine1,
          addressLine2: shipInfo.addressLine2,
          // landmark: shipInfo.landmark,
          city: shipInfo.city,
          state: shipInfo.state,
          ZipCode: shipInfo.pinCode,
          action: (
            <>
              <a
                style={{ color: "Blue" }}
                onClick={() => editAddress(shipInfo)}
              >
                Edit
              </a>
              &nbsp; &nbsp;
              <a
                style={{ color: "Red" }}
                onClick={() => deleteAddress(shipInfo)}
              >
                Delete
              </a>
            </>
          ),
        })
      )
    ) || []; */

  console.log("profileBillingTableData : ", profileBillingTableData);
  /* console.log("profileShippingTableData : ", profileShippingTableData); */

  useEffect(() => {
    profileClient.getProfile(dispatch);
  }, []);

  useEffect(() => {
    if (profileData._id) {
      console.log("profileData : ", profileData);
      setName(profileData.name);
      setEmail(profileData.email);
      setPhone(maskingPhone(profileData.phone));
      setDob(profileData.dob);

      /* fetch billing addresses */
      profileClient.getProfileAddresses(dispatch, profileData._id, "");

      /* fetch shipping addresses */
      /* profileClient.getProfileAddresses(dispatch, profileData._id, "shipping"); */
    }
  }, [profileData]);

  useEffect(() => {
    console.log("profileBillingTableData : ", profileBillingTableData);
    /* console.log("profileShippingTableData : ", profileShippingTableData); */
  }, [profileBillingTableData]); /* , profileShippingTableData */

  const fetchAddresses = () => {
    setIsDelete(false);
    setIsBillingModalVisible(false);
    /* setIsShippingModalVisible(false); */
    /* fetch billing addresses */
    profileClient.getProfileAddresses(dispatch, profileData._id, "");

    /* fetch shipping addresses */
    /* profileClient.getProfileAddresses(dispatch, profileData._id, "shipping"); */
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    let name = e.target.value;
    setName(name);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    let email = e.target.value.trim();
    setEmail(email);
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let phone = e.target.value;
    phone = maskingPhone(phone);
    setPhone(phone);
  };

  const handleProfileDataSave = async () => {
    let formData = new FormData();
    formData.append("_id", profileData._id);
    formData.append("name", name.trim());
    formData.append("email", email);
    formData.append("phone", (phone.replace(/-/g, "") + "").trim());
    formData.append("dob", dob);

    const profileDataUpdateResponse = await profileClient.updateProfile(
      /* {
        _id: profileData._id,
        name: name,
        email: email,
        phone: phone + "",
      } */ formData,
      dispatch
    );

    if (profileDataUpdateResponse?.message != "") {
      toast.success({
        message: `${profileDataUpdateResponse?.message}`,
        delay: 4000,
      });

      profileClient.getProfile(dispatch);
    }
  };

  /* const handleAddShipping = () => {
    setSelectedAddress(false);
    setIsShippingModalVisible(!isShippingModalVisible);
  }; */

  const handleAddBilling = () => {
    setSelectedAddress(false);
    setIsBillingModalVisible(!isBillingModalVisible);
  };

  const handleAccordion = (id: number) => {
    setSelectedAccordian(id);
  };

  const editAddress = (addr: IProfileAddressState) => {
    setSelectedAddress(addr);
    /* if (addr.addressType == "billing") {
      setIsBillingModalVisible(!isBillingModalVisible);
    } else {
      setIsShippingModalVisible(!isShippingModalVisible);
    } */

    setIsBillingModalVisible(!isBillingModalVisible);
  };

  const deleteAddress = (addr: IProfileAddressState) => {
    setSelectedAddress(addr);
    setIsDelete(true);
    setIsBillingModalVisible(false);
    /* setIsShippingModalVisible(false); */
  };

  const checkValue = (str: string, max: number): string => {
    if (str.charAt(0) !== '0' || str === '00') {
      let num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
    }
    return str;
  };
  
  const dobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    let formattedInput = input;
    if (/\D\/$/.test(input)) formattedInput = input.substr(0, input.length - 3);
    const values = formattedInput.split('/').map(v => v.replace(/\D/g, ''));
    if (values[0]) values[0] = checkValue(values[0], 12); 
    if (values[1]) values[1] = checkValue(values[1], 31); 
    const output = values.map((v, i) => (v.length === 2 && i < 2 ? v + ' / ' : v)).join('').substring(0, 7);
    setDob(output);
  };
  
  const dobBlur = () => {
    const values = dob.split('/').map(v => v.replace(/\D/g, ''));
    let output = '';
    console.log("values : ", values);
    if (values.length >= 2) {
      const day = parseInt(values[1]); 
      const month = parseInt(values[0]); 
      if (!isNaN(day) && !isNaN(month) && day <= 31 && month <= 12) {
        output = [month, day].map(v => (v < 10 ? '0' + v : v)).join(' / ');
      }
    }
    setDob(output);
  };

  return (
    <>
      
        <style>
          {`
          /**
           * Tooltip Styles
           */

          /* Add this attribute to the element that needs a tooltip */
          [data-tooltip] {
            position: relative;
            z-index: 2;
            cursor: pointer;
          }

          /* Hide the tooltip content by default */
          [data-tooltip]:before,
          [data-tooltip]:after {
            visibility: hidden;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
            opacity: 0;
            pointer-events: none;
          }

          /* Position tooltip above the element */
          [data-tooltip]:before {
            position: absolute;
            bottom: 150%;
            left: 50%;
            margin-bottom: 5px;
            margin-left: -35px;
            padding: 7px;
            width: 60px;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            background-color: #000;
            background-color: hsla(0, 0%, 20%, 0.9);
            color: #fff;
            content: attr(data-tooltip);
            text-align: center;
            font-size: 14px;
            line-height: 1.2;
          }

          /* Triangle hack to make tooltip look like a speech bubble */
          [data-tooltip]:after {
            position: absolute;
            bottom: 150%;
            left: 50%;
            margin-left: -5px;
            width: 0;
            border-top: 5px solid #000;
            border-top: 5px solid hsla(0, 0%, 20%, 0.9);
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            content: " ";
            font-size: 0;
            line-height: 0;
          }

          /* Show tooltip content on hover */
          [data-tooltip]:hover:before,
          [data-tooltip]:hover:after {
            visibility: visible;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
            opacity: 1;
          }`}
        </style>
      
      <FormGroup>
        <AccordionContainer>
          <Accordion
            handleToggle={handleAccordion}
            header="Profile Information"
            id={1}
            active={selectedAccordian}
          >
            <FormContainer>
              <FormGroup style={{ marginRight: "2%" }}>
                <Input
                  placeholder="First and Last Name"
                  autoComplete="off"
                  value={name}
                  onChange={handleNameChange}
                  label="Full Name (First and Last Name)"
                />
              </FormGroup>
              <FormGroup style={{ marginRight: "2%" }}>
                <Input
                  placeholder="Email"
                  autoComplete="off"
                  value={email}
                  onChange={handleEmailChange}
                  label="Email"
                />
              </FormGroup>
              <FormGroup style={{ marginRight: "2%" }}>
                <Input
                  placeholder="Phone"
                  autoComplete="off"
                  value={phone}
                  onChange={handlePhoneChange}
                  label="Phone"
                />
              </FormGroup>
              <FormGroup style={{ marginRight: "2%" }}>
                <Input
                  placeholder="Month / Day"
                  autoComplete="off"
                  value={dob}
                  onChange={dobChange}
                  onBlur={dobBlur}
                  label="Date of Birth (MM / DD)"
                />
              </FormGroup>
              <FormGroup>
                <ButtonAlpha
                  style={{ height: "fit-content", margin: "50%" }}
                  onClick={handleProfileDataSave}
                >
                  Save
                </ButtonAlpha>
              </FormGroup>
            </FormContainer>
          </Accordion>
          <Accordion
            handleToggle={handleAccordion}
            header="Address Information"
            id={2}
            active={selectedAccordian}
          >
            <FormContainer>
              <FormGroup style={{width:"100%"}}>
                <ButtonAlpha
                  style={{ height: "fit-content", margin: "0.1%" }}
                  onClick={handleAddBilling}
                >
                  Add Address
                </ButtonAlpha>
              </FormGroup>
            </FormContainer>
              {profileBillingTableData.length > 0 && (
                <>
                  <Center V={true} H={true} style={{width:"100%"}}>
                    <Table data={profileBillingTableData} fullWidth={true} />
                  </Center>
                </>
              )}
            
            <AddressForm
              title="Address Info"
              isOpen={isBillingModalVisible}
              close={() => handleAddBilling()}
              onDone={fetchAddresses}
              addressType="billing"
              profileData={profileData}
              selectedAddress={selectedAddress}
              isDelete={isDelete}
            />
          </Accordion>
          {/* <Accordion
            handleToggle={handleAccordion}
            header="Shipping Address Information"
            id={3}
            active={selectedAccordian}
          >
            <FormGroup>
              <ButtonAlpha
                style={{ height: "fit-content", margin: "0.1%" }}
                onClick={handleAddShipping}
              >
                Add Shipping
              </ButtonAlpha>
            </FormGroup>

            <FormContainer>
              {profileShippingTableData.length > 0 && (
                <>
                  <Center V={true} H={true}>
                    <Table data={profileShippingTableData} />
                  </Center>
                </>
              )}
            </FormContainer>
            <AddressForm
              title="Shipping Info"
              isOpen={isShippingModalVisible}
              close={() => handleAddShipping()}
              onDone={fetchAddresses}
              addressType="shipping"
              profileData={profileData}
              selectedAddress={selectedAddress}
              isDelete={isDelete}
            />
          </Accordion> */}
        </AccordionContainer>
      </FormGroup>
    </>
  );
};
