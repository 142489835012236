import styled from "styled-components";
import { primaryColor, themeWhite } from "../../constants";

const Container = styled.div`
  padding: 0 30px;
`;

const StyledPBlue = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: #142f8f;
`;
const StyledUl = styled.ul`
  list-style: none;
  /*overflow-y: scroll;*/
`;
const StyledLi = styled.li`
  color: #142f8f;
  &:hover {
    color: rgb(255 255 255);
    background-color: rgb(20 47 143);
  }
`;

const AccordionCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  margin: 10px;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`;

const AccordionHeader = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? themeWhite : primaryColor)};
  background-color: ${(props) => (props.active ? primaryColor : themeWhite)};
  font-size: 24px;
  padding: 1px;

  & h5 {
    margin: 0;
    padding: 10px 0 10px 10px;
  }
`;

const AccordionCollapse = styled.div<{ collapse: boolean }>`
  position: relative;
  overflow: hidden;
  transition: height 0.35s ease;
  height: ${(props) => (props.collapse ? "auto" : "0")};
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0 10px 10px;
`;

const FontPreviewDiv = styled.div`
  width: 400px;
  float: left;
  margin-right: 30px;
  margin-left: 15px;
`;

const FontNameDiv = styled.div`
  width: 250px;
  float: left;
  max-height: 300px;
  overflow: auto;
  padding-left: 10px;
  margin-bottom: 20px;
`;

const Content = styled.p`
  line-height: 1.5;
`;

const Image = styled.img`
  max-width: 100%;
  height: 198px;
  width: 396px;
`;

export {
  Container,
  StyledPBlue,
  StyledUl,
  StyledLi,
  AccordionCard,
  AccordionHeader,
  AccordionCollapse,
  FontPreviewDiv,
  FontNameDiv,
  Content,
  Image,
};
