import { AnyAction } from "redux";
import { UPDATE_EMBROIDARY_VIEW } from "../actions";

interface IEmbroidaryReducer {

}

const initialState: IEmbroidaryReducer = {
  embroidaryView: {
    front: {
      specialInstructions:""
    },
    back: {
      specialInstructions:""
    },
    arm_right: {
      specialInstructions:""
    },
    arm_left: {
      specialInstructions:""
    },
    cuff_right: {
      specialInstructions:""
    },
    cuff_left: {
      specialInstructions:""
    },
    collar: {
      specialInstructions:""
    },
    collar_flipper_up: {
      specialInstructions:""
    },
    hood_forward: {
      specialInstructions:""
    },
    hood_backward: {
      specialInstructions:""
    }
  },
};

export function embroidary(
  state: IEmbroidaryReducer = initialState,
  action: AnyAction
): IEmbroidaryReducer {
  switch (action.type) {
    case UPDATE_EMBROIDARY_VIEW:
      return {
        ...state,
        embroidaryView: action.embroidaryView,
      };
    default:
      return state;
  }
}
